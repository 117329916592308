@include block(book-shelf) {
  @include elem(container) {
    align-items: center;
    display: flex;
  }
  @include elem(image) {
    @include flex-width(6, tablet);
    display: block;
    text-align: center;
    @include media-breakpoint-up(tablet) {
      text-align: right;
    }
  }
  @include elem(content) {
    @include flex-with-margin(6, .5, left, tablet);
    margin: 0 auto;
    max-width: 80% !important;
    text-align: center;
    @include media-breakpoint-up(tablet) {
      max-width: 33% !important;
      text-align: left;
    }
  }
  @include elem(heading) {
    @include heading-display(h2);
    color: $red;
  }
  @include elem(subheading) {
    @include subheading();
  }


}
