@include block(ad) {
  @include auto-center();
  display: flex;
  background-color: $ultralight-grey;
  padding: {
    bottom: px-to-rem(40);
    top: px-to-rem(40);
  }
  position: relative;
  text-align: center;
  z-index: 40;
  &:before {
    color: rgba($dark-blue-grey, .7);
    content: 'Pubblicità';
    display: block;
    font-size: px-to-rem(8);
    left: 0;
    letter-spacing: .5px;
    line-height: 1;
    opacity: .57;
    position: absolute;
    text: {
      align: center;
      transform: uppercase;
    }
    top: px-to-rem(20);
    width: 100%;
  }
  @include media-breakpoint-down(desktop) {
    margin: {
      bottom: px-to-rem(70);
      top: px-to-rem(70);
    }
  }
  @include mod(stripe) {
    @include section();
  }
  @include mod(zone) {
    margin-top: 0 !important;
  }
  @include mod(header-top) {
    margin: 0;
    padding-bottom: px-to-rem(32);
  }
  @include mod(top) {
    margin: 0;
    padding-bottom: px-to-rem(32);
  }
  @include mod(fixed) {
    @media screen and (max-width: 780px) {
      height: calc(  #{px-to-rem(250)} + 4.5rem); //4.5rem is the padding
    }
    @media screen and (min-width: 781px) and (max-width: 1007px) {
      height: calc( #{px-to-rem(106)} + 4.5rem); //4.5rem is the padding
    }
    @media screen and (min-width: 1008px) {
      height: calc( #{px-to-rem(250)} + 4.5rem); //4.5rem is the padding
    }
  }
  @include mod(breaker) {
    clear: both;
    margin: {
      left: calc(-50vw + 50%);
      top: px-to-rem(48) !important;
    }
    width: 100vw;
    @include media-breakpoint-up(phone) {
      margin: {
        left: calc(-50vw + 50%);
      }
    }
    @include media-breakpoint-up(desktop) {
      margin: {
        left: calc(-50vw + 50%);
      }
    }
    @include media-breakpoint-up(hires) {
      margin: {
        left: calc(-50vw + 50%);
      }
    }
  }
  @include mod(sponsor) {
    background-color: white;
    clear: both;
    margin: {
      left: calc(-50vw + 50%);
      top: px-to-rem(48) !important;
    }
    width: 100vw;
    @include media-breakpoint-up(phone) {
      margin: {
        left: calc(-50vw + 50%);
      }
    }
    @include media-breakpoint-up(desktop) {
      margin: {
        left: calc(-50vw + 50%);
      }
    }
    @include media-breakpoint-up(hires) {
      margin: {
        left: calc(-50vw + 50%);
      }
    }
  }
  > div {
    margin: 0 auto;
  }

}
