@include block(modal) {
  background: $white;
  height: auto;
  width: 100vw;
  @include media-breakpoint-up(desktop) {
    bottom: auto;
    box-shadow: 2px 2px px-to-rem(20) 1px;
    display: flex;
    flex-direction: column;
    max-width: px-to-rem($modal-max-width);
    overflow-x: auto;
    position: relative;
    width: 70vw;
  }
  @include elem(container) {
    @include media-breakpoint-up(desktop) {
      padding: px-to-rem(48) px-to-rem(36) px-to-rem(48);
    }
  }
  @include elem(header) {
    @include heading-display(h4);
    line-height: 1;
    margin-bottom: px-to-rem(32);
    @include media-breakpoint-down(tablet) {
      font-size: px-to-rem(24);
    }
  }
  @include elem(body) {
    position: relative;
  }
  @include elem(backdrop) {
    @include fullscreen();
    align-items: center;
    background-color: rgba($black, .5);
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 999;
  }
  @include elem(close) {
    position: absolute;
    top: -#{px-to-rem(10)};
    right: -#{px-to-rem(10)};
    > svg {
      height: px-to-rem(19);
      width: px-to-rem(19);
    }
  }
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease
}
