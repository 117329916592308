@include block(card) {
  background-color: $white !important;
  display: block;
  @include mod(tall) {
    box-shadow: none;
    color: $white;
    max-height: px-to-rem(520);
    height: 69vw !important;
    padding: px-to-rem(16);
    position: relative;
    z-index: 10;
    @include media-breakpoint-up(phone) {
      height: 50vw !important;
    }
    @include media-breakpoint-up(desktop) {
      height: 38vw !important;
    }
    @include block(card) {
      @include elem(image-container) {
        @include auto-center();
        @include fullscreen(px-to-rem(8));
        background: {
          color: $alt-dark-background;
          position: 50%;
          repeat: no-repeat;
          size: cover;
        }
        margin: initial;
        overflow: hidden;
        width: calc(100% - #{px-to-rem(16)});
      }
    }
  }
  @include mod(animated) {
    transition: transform .4s ease-in-out;
    @include media-breakpoint-up(tablet) {
      &:hover {
        //box-shadow: 0 px-to-rem(28) px-to-rem(81) -#{px-to-rem(8)} rgba($black, .44);
        transform: translateY(-#{px-to-rem(8)});
        z-index: 19;
      }
    }
  }
  @include elem(gradient-overlay) {
    @include fullscreen();
    background-image: linear-gradient(-180deg, transparent 53%, $black);
    opacity: .45;
    z-index: 1;
  }
  @include elem(content) {
    background: linear-gradient(-180deg, rgba($black, 0) 53%, $black 100%);
    bottom: 0;
    color: $white;
    font-size: px-to-rem(18);
    left: 0;
    padding: 0 px-to-rem(24) px-to-rem(30);
    position: absolute;
    right: 0;
    text-align: center;
    z-index: 100;
    @include media-breakpoint-up(tablet) {
      min-height: px-to-rem(160);
    }
  }
  @include elem(marker) {
    @include numberMarker(solid);
    margin-bottom: px-to-rem(20);
  }
  @include elem(eyebrow) {
    color: $white;
    font: {
      family: $font-family-secondary;
      size: px-to-rem(12);
      style: italic;
    }
    line-height: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    @include media-breakpoint-up(phone) {
      font-size: px-to-rem(14);
    }
    + .card__title {
      margin-top: px-to-rem(6);
    }
  }
  @include elem(title) {
    color: $white;
    font: {
      size: px-to-rem(20);
      weight: $font-weight-bold;
    }
    line-height: 1.1;
    @include media-breakpoint-up(tablet) {
      font: {
        size: px-to-rem(24);
      }
    }
    + .card__ttd {
      margin-top: px-to-rem(10);
    }
  }
  @include elem(subtitle) {
    bottom: px-to-rem(16);
    color: $white;
    font: {
      size: px-to-rem(13);
      weight: $font-weight-bold;
    }
    left: px-to-rem(16);
    position: absolute;
    @include media-breakpoint-up(tablet) {
      bottom: px-to-rem(32);
      font: {
        size: px-to-rem(16);
      }
      left: px-to-rem(32);
    }
  }
  @include elem(ttd) {
    color: $white;
    font: {
      family: $font-family-main;
      size: px-to-rem(10);
    }
    line-height: 1;
    text: {
      transform: uppercase;
      overflow: ellipsis;
    }
    white-space: nowrap;
    width: 100%;
  }
  @include elem(svgicon) {
    margin: 0 auto;
    width: 70%;
    + .card__title {
      margin-top: px-to-rem(6);
    }
  }
}

@include block(card-desk) {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  @include block(card) {
    border: 3px solid $white;
    @include flex-width(6);
    @include flex-width(4, phone);
    @include flex-width(3, desktop);
    &:nth-child(n+4) {
      @include media-breakpoint-between(phone, tablet) {
        display: none;
      }
    }
    @include elem(content) {
      padding: 0 px-to-rem(32) px-to-rem(32);
    }
    @include elem(title) {
      line-height: 1.1;
    }
  }
}
//> div {
//  p {
//    bottom: 1.2rem;
//    color: $white;
//    font-size: 1.3rem;
//    font-weight: $font-weight-semibold;
//    left: 1.6rem;
//    letter-spacing: -0.08px;
//    position: absolute;
//    z-index: 10;
//    @include responsive-tablet() {
//      bottom: 2.4rem;
//      font: {
//        size: 1.4rem;
//        weight: $font-weight-semibold;
//      }
//      left: 3.2rem;
//    }
//    @include responsive-desktop() {
//      bottom: 2.4rem;
//      font-size: 1.6rem;
//      left: 3.2rem;
//    }
//  }
//  .slide-content {
//    background: linear-gradient(-180deg, rgba($black, 0) 53%, $black 100%);
//    bottom: 0;
//    color: $white;
//    font-size: 1.8rem;
//    left: 0;
//    padding: 0 1rem 1.5rem;
//    position: absolute;
//    right: 0;
//    text-align: center;
//    z-index: 100;
//    @include responsive-phone() {
//      padding: 0 1.5rem 2rem;
//    }
//    @include responsive-tablet() {
//      min-height: 14.5rem;
//    }
//    .slide-marker {
//      @extend .marker;
//      margin-bottom: 1.4em;
//      @include responsive-desktop() {
//        font-size: 1.6rem !important;
//      }
//    }
//    p {
//      font: {
//        family: $font-family-secondary;
//        size: 1.2rem;
//        style: italic;
//      }
//      left: 0;
//      letter-spacing: normal;
//      line-height: 1;
//      margin: 0;
//      overflow: hidden;
//      position: relative;
//      text-overflow: ellipsis;
//      top: 0;
//      white-space: nowrap;
//      width: 100%;
//      @include responsive-phone() {
//        font-size: 1.4rem;
//      }
//    }
//    h3 {
//      color: $white;
//      font: {
//        size: 1.2rem;
//        weight: $font-weight-bold;
//      }
//      line-height: 1.5;
//      margin-top: .6rem;
//      overflow: hidden;
//      text: {
//        transform: uppercase;
//        overflow: ellipsis;
//      }
//      @include responsive-phone() {
//        font-size: 1.4rem;
//        letter-spacing: .06rem;
//        line-height: 1.35714;
//        margin-top: 1.2rem;
//      }
//    }
//  }
//}
//@include elem(inner) {
//    padding: 1.2em;
//}
//@include elem(image-top) {
//    display: block;
//    height: 180px;
//    overflow: hidden;
//    text-align: center;
//
//    img {
//        margin: auto;
//        max-width: 100%;
//    }
//}
//@include elem(block) {
//    flex: 1 1 auto;
//    padding: 1.25rem;
//    @include elem(title) {
//        margin-bottom: .75rem;
//    }
//}
//
//@include mod(3 cols) {
//    width: calc(100% / 3);
//    @include responsive-phone-max() {
//        width: 100%;
//        margin-bottom: 5em;
//    }
//}
//
//@include elem(bottom) {
//    border-bottom: 1px solid $alphalines;
//    margin: 0 auto;
//    padding-top: 1rem;
//    right: 0;
//    text-align: center;
//    width: 90%;
//    z-index: 1;
//    @include elem(link) {
//        color: $white;
//        display: table-cell;
//        font: {
//            size: 1.2rem;
//            weight: $font-weight-bold
//        }
//        line-height: 1.25;
//        text: {
//            align: center;
//            transform: uppercase;
//        }
//        vertical-align: middle;
//    }
//}

//FGreco - 24 Maggio 2017 - Molto probabilmente la mod "intrests" per adesso non serve
// @include mod(interests) {
//     float: left;
//     height: 220px;
//     overflow: hidden;
//     transition: transform .4s;
//     width: 220px;
//     @include elem(inner) {
//         background: {
//             color: $light-grey;
//             position: 0;
//             size: cover;
//         }
//         height: 190px;
//         left: 50%;
//         position: relative;
//         top: 50%;
//         transform: translate(-50%, -50%);
//         width: 190px;
//         &:before {
//             @extend %fullscreen;
//             background: linear-gradient(0deg, rgba($black, .4) 0, transparent 32%);
//             content: "";
//             display: block;
//         }
//     }
//     @include elem(bottom) {
//         border-top: 1px solid $alphalines;
//         bottom: 1rem;
//         display: table;
//         height: 4rem;
//         left: 0;
//         margin: 0 auto;
//         padding-top: 1rem;
//         position: absolute;
//         right: 0;
//         text-align: center;
//         width: 90%;
//         z-index: 1;
//         @include elem(link) {
//             color: $white;
//             display: table-cell;
//             font: {
//                 size: 1.2rem;
//                 weight: $font-weight-bold
//             }
//             line-height: 1.25;
//             text: {
//                 align: center;
//                 transform: uppercase;
//             }
//             vertical-align: middle;
//         }
//     }
// }
