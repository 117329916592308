@mixin container() {
  @include auto-center(px-to-rem(15));
  max-width: $content-max-width;
  position: relative;
  @include media-breakpoint-up(phone) {
    @include auto-center(px-to-rem(30));
  }
  @include media-breakpoint-up(desktop) {
    @include auto-center(px-to-rem(60));
  }
  @include media-breakpoint-up(hires) {
    @include auto-center();
  }
}

@mixin section($multiplier: 1) {
  @include clearfix-only-after();
  margin: {
    bottom: px-to-rem(40*$multiplier);
  }
  @include media-breakpoint-up(tablet) {
    margin: {
      bottom: px-to-rem(70*$multiplier);
    }
  }
  @include media-breakpoint-up(desktop) {
    margin: {
      bottom: px-to-rem(100*$multiplier);
    }
  }
}

@mixin section-main($multiplier: 1) {
  margin: {
    top: px-to-rem(40*$multiplier);
  }
  @include media-breakpoint-up(tablet) {
    margin: {
      top: px-to-rem(70*$multiplier);
    }
  }
  @include media-breakpoint-up(desktop) {
    margin: {
      top: px-to-rem(100*$multiplier);
    }
  }
}

@mixin section-border($multiplier: 1, $position: top) {
  border-#{$position}: 1px solid $border-color;
  padding: {
    #{$position}: px-to-rem(40*$multiplier);
  }
  @include media-breakpoint-up(tablet) {
    padding: {
      #{$position}: px-to-rem(70*$multiplier);
    }
  }
  @include media-breakpoint-up(desktop) {
    padding: {
      #{$position}: px-to-rem(100*$multiplier);
    }
  }
}

@mixin clearfix($display: block) {
  &::before,
  &::after {
    content: " ";
    display: $display;
  }
  &::after {
    clear: both;
  }
}

@mixin clearfix-only-after($display: block) {
  &::after {
    clear: both;
    content: " ";
    display: $display;
  }
}

@mixin appearance($appearance: none) {
  -ms-progress-appearance: $appearance;
  -webkit-appearance: $appearance;
  -moz-appearance: $appearance;
}

@mixin auto-center($size: auto) {
  margin: {
    left: $size;
    right: $size;
  }
}

@mixin auto-vertical($size: auto) {
  margin: {
    bottom: $size;
    top: $size;
  }
}

@mixin fullscreen($size: 0) {
  bottom: $size;
  left: $size;
  position: absolute;
  right: $size;
  top: $size;
}

@mixin cover() {
  background: {
    color: $image-background;
    position: 50% center;
    repeat: no-repeat;
    size: cover;
  }
  overflow: hidden;
}

@mixin figure() {
  @include fullscreen();
  @include cover();
}

@mixin cover-shadow() {
  box-shadow: 0 6px 6px -3px rgba(0, 0, 0, .2), 0 10px 14px 1px rgba(0, 0, 0, .14), 0 4px 18px 3px rgba(0, 0, 0, .12);
}

@mixin cover-shadow-elevation-2() {
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 1px 8px 0 rgba(0, 0, 0, .12);
}

@mixin after-chevron-animation() {
  > svg {
    backface-visibility: hidden;
    display: inline-block;
    fill: currentColor;
    font-size: px-to-rem(6);
    line-height: 1.6;
    transform: translateX(px-to-rem(6));
    transition: transform .5s;
    vertical-align: 15%;
  }
  &:hover {
    > svg {
      transform: translateX(px-to-rem(2));
    }
  }
}

@mixin before-chevron-animation() {
  > svg {
    backface-visibility: hidden;
    display: inline-block;
    font-size: px-to-rem(6);
    line-height: 1;
    transform: translateX(-(px-to-rem(6)));
    transition: transform .5s;
    vertical-align: 20%;
  }
  &:hover {
    > svg {
      transform: translateX(-(px-to-rem(2)));
    }
  }
}

@mixin more-chevron-animation() {
  > svg {
    backface-visibility: hidden;
    display: inline-block;
    fill: currentColor;
    font-size: px-to-rem(6);
    line-height: 1.6;
    margin-left: px-to-rem(3);
    transform: translateY(0);
    transition: transform .5s, -webkit-transform .5s;
    vertical-align: 15%;
  }
  &:hover {
    > svg {
      transform: translateY(px-to-rem(2));
    }
  }
}

@mixin less-chevron-animation() {
  > svg {
    backface-visibility: hidden;
    display: inline-block;
    fill: currentColor;
    font-size: px-to-rem(6);
    line-height: 1.6;
    margin-left: px-to-rem(3);
    transform: translateY(0);
    transition: transform .5s, -webkit-transform .5s;
    vertical-align: 15%;
  }
  &:hover {
    > svg {
      transform: translateY(-(px-to-rem(2)));
    }
  }
}

@mixin bottom-shadow() {
  border-radius: 100%;
  bottom: 0;
  box-shadow: 0 0 px-to-rem(30) rgba($black, .1);
  content: '';
  height: 50%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
}

@mixin numberMarker($type: diamond) {
  backface-visibility: hidden;
  color: $main-text-color;
  display: inline-block;
  font: {
    family: $font-family-secondary;
    size: px-to-rem(14);
  }
  height: px-to-rem(25);
  line-height: px-to-rem(25);
  position: relative;
  text-align: center;
  width: px-to-rem(22);
  z-index: 1;
  @if $type==diamond {
    .number-marker-diamond {
      background-color: transparent;
      border-radius: 5px;
      border: 1px solid #fff;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, .18);
      display: block;
      height: px-to-rem(24);
      margin-top: -#{px-to-rem(24)};
      opacity: .5;
      position: relative;
      transform: rotate(45deg);
      width: px-to-rem(24);
      z-index: -1;
    }
  } @else if $type==solid {
    &:after {
      background-color: $white;
      border-radius: px-to-rem(4);
      box-shadow: rgba($black, .18) 2px 2px 2px;
      content: "";
      display: block;
      height: 105%;
      left: -3px;
      margin-top: -(px-to-rem(26));
      position: relative;
      transform: rotate(45deg);
      width: 123%;
      z-index: -1;
    }
  }
}

@mixin imageZoom() {
  @include block(img) {
    transition: transform 3s ease-out, opacity .5s, -webkit-transform 3s ease-out;
  }
  &:hover {
    @include block(img) {
      opacity: .5;
      transform: scale(1.1);
    }
  }
}

@mixin flex-reset() {
  display: block;
  flex-basis: 100%;
  max-width: 100%;
  position: relative;

}

@mixin input() {
  @include appearance();
  background: $white;
  border-radius: 0;
  border: 1px solid $input-border;
  color: $main-text-color;
  font: {
    size: px-to-rem(18);
    weight: $font-weight-light;
  }
  height: px-to-rem(44);
  line-height: px-to-rem(44);
  max-width: px-to-rem(200);
  padding: 0 px-to-rem(16);
  &:focus {
    border-color: $brand-color;
    outline: none;
    transition: border 200ms;
  }
  &::after {
    display: none;
  }
  @include mod(error) {
    border-bottom: 2px solid $red !important;
  }
}

@mixin inputFull() {
  @include mod(full) {
    width: 100%;
    max-width: 100%;
  }
}

@mixin inputErrorIcon() {
  &::after {
    background: transparent url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 489.307 489.307" style="enable-background:new 0 0 489.307 489.307;" xml:space="preserve"><polygon points="489.307,56.466 432.839,0 244.654,188.187 56.468,0 0,56.466 188.186,244.654 0,432.839 56.469,489.307 244.654,301.121 432.839,489.307 489.306,432.839 301.121,244.654" fill="#{url-friendly-colour($red)}"/></svg>') no-repeat center;
    content: '';
    fill: $red;
    height: px-to-rem(12);
    position: absolute;
    right: px-to-rem(20);
    text-transform: none;
    top: px-to-rem(16);
    width: px-to-rem(12);
  }

}
