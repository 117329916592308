@include block(box-group) {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin-top: px-to-rem(64);
  max-width: $content-max-width;
  @include media-breakpoint-down(phone) {
    display: block;
  }
  @include elem(item) {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    max-width: px-to-rem(320);
    text-align: center;
    @include media-breakpoint-down(phone) {
      margin-bottom: px-to-rem(48);
      max-width: none;
      width: 100%;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    svg {
      color: $link-color;
      display: inline-block;
      fill: currentColor;
      height: px-to-rem(96);
      line-height: 1;
      transition: transform .4s ease-in-out;
      vertical-align: middle;
      width: px-to-rem(96);
    }
    @include elem(heading) {
      color: $main-text-color;
      font: {
        size: px-to-rem(20);
        weight: $font-weight-bold;
      }
      letter-spacing: -.2px;
      margin-top: px-to-rem(32);
      line-height: 1.4;
    }
    @include elem(copy) {
      color: $alt-text-color;
      font: {
        size: px-to-rem(16);
      }
      line-height: 1.3;
      margin: px-to-rem(10) auto px-to-rem(24);
    }
    @include elem(link) {
      display: block;
      &:hover {
        svg {
          transform: translateY(-#{px-to-rem(5)});
        }
        h3 {
          color: $link-color;
        }
      }
    }
  }
}
