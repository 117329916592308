@include block(carousel) {
  overflow: hidden;
  position: relative;
  @include mod(tall) {
    margin: 0 -#{px-to-rem(32)};
    padding: 0 px-to-rem(16) 0 px-to-rem(32);
    @include block(slick-list) {
      overflow: hidden;
      padding: px-to-rem(32) 0;
    }
  }
  @include elem(button) {
    background: transparent;
    border: none;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 100%;
    line-height: 0;
    margin: 0;
    outline: none;
    padding: 0;
    position: absolute;
    top: 0;
    width: px-to-rem(30);
    z-index: 1;
    &:before {
      background-color: $white;
      border-radius: 50%;
      box-shadow: px-to-rem(32) 0 px-to-rem(482) -#{px-to-rem(8)} rgba($black, .25);
      content: '';
      display: block;
      height: px-to-rem(40);
      opacity: 1;
      overflow: hidden;
      text-indent: 200%;
      white-space: nowrap;
      width: px-to-rem(40);
      z-index: 2;
    }
    @include media-breakpoint-up(tablet) {
      display: inline-block;
      z-index: 20;
    }
    @include mod(previous) {
      left: px-to-rem(13.2);
      svg {
        left: px-to-rem(11);
      }
      &:after {
        background-color: white;
        box-shadow: px-to-rem(16) 0 px-to-rem(21) -#{px-to-rem(12)} rgba($black, .5);
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        right: px-to-rem(10);
        top: 0;
        width: 100%;
        z-index: -1;
      }
    }
    @include mod(next) {
      right: px-to-rem(22);
      svg {
        right: #{px-to-rem(1)};
      }
      &:after {
        background-color: white;
        box-shadow: -#{px-to-rem(15)} 0 px-to-rem(21) -#{px-to-rem(12)} rgba($black, .5);
        content: '';
        display: block;
        height: 100%;
        left: px-to-rem(20);
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
      }
    }
    > svg {
      color: $main-text-color;
      position: absolute;
      top: 48%;
      transition: color .4s;
      @include media-breakpoint-up(tablet) {
        top: 48.65%;
      }
    }
    &:hover {
      > svg {
        color: $link-color;
      }
    }
  }
  @include block(slick-track) {
    margin: 0 -.5rem;
  }

  @include mod(catalog) {
    margin: 0 -#{px-to-rem(32)};
    padding: 0 px-to-rem(9) 0 px-to-rem(31);
    @include block(carousel) {
      @include elem(button) {
        @include mod(next) {
          right: px-to-rem(19);
        }
        > svg {
          @include media-breakpoint-up(tablet) {
            top: 47.8%;
          }
        }
      }
    }
    @include block(slick-slide) {
      margin-top: px-to-rem(16);
    }
  }
}
