@include block(button) {
  @include appearance();
  backface-visibility: hidden;
  background-color: $brand-color;
  border: 1px solid $brand-color;
  border-radius: px-to-rem(100);
  box-sizing: border-box !important;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font: {
    family: $font-family-main;
    size: px-to-rem(12);
    weight: $font-weight-bold;
  }
  line-height: 1;
  outline: none;
  padding: px-to-rem(18) px-to-rem(30) px-to-rem(14);
  text: {
    align: center;
    transform: uppercase;
  }
  transition: {
    duration: .3s;
    property: color, border, background-color;
  }
  vertical-align: middle;
  white-space: nowrap;
  > svg {
    color: inherit;
  }
  &:hover {
    background-color: color_mixer($brand-color, 15);
    color: $white;
  }
  @include mod(offbeat) {
    background-color: transparent;
    border: 1px solid $button-line-color;
    color: $main-text-color;
    @include block(icon) {
      color: inherit;
    }
    &:hover {
      background-color: transparent;
      border-color: $button-line-color;
      color: color-mixer($main-text-color, 25);
    }
  }
  @include mod(offbeat-blue) {
    background-color: transparent;
    border: 1px solid $brand-color;
    color: $brand-color;
    @include block(icon) {
      color: inherit;
    }
    &:hover {
      background-color: transparent;
      border-color: color_mixer($brand-color, 15);
      color: color_mixer($brand-color, 15);
    }
  }
  @include mod(link) {
    background-color: transparent;
    border: 0 !important;
    color: $brand-color;
    font-size: px-to-rem(12);
    padding: 0;
    &::before {
      background-color: $button-line-color;
      content: '';
      display: block;
      height: 1px;
      margin: {
        bottom: px-to-rem(20);
        left: -(px-to-rem(20));
      }
      width: calc(100% + #{px-to-rem(40)});
    }
    &:hover {
      background-color: transparent;
      border: 0 !important;
      color: $brand-color;
      font-size: px-to-rem(12);
      padding: 0;
    }
    @include mod(flat) {
      &::before {
        display: none;
      }
    }
    @include mod(left) {
      &::before {
        margin-left: 0;
      }
    }
    @include block(icon) {
      color: inherit;
      vertical-align: 10%;
    }
  }
  @include mod(small) {
    font-size: px-to-rem(11);
    padding: px-to-rem(14) px-to-rem(28) px-to-rem(12);
    @include block(icon) {
      vertical-align: 10%;
    }
    &:hover {
      font-size: px-to-rem(11);
      padding: px-to-rem(14) px-to-rem(28) px-to-rem(12);
    }
  }
  @include mod(extra-small) {
    font-size: px-to-rem(10);
    padding: px-to-rem(10) px-to-rem(20) px-to-rem(9);
    @include block(icon) {
      vertical-align: 10%;
    }
    &:hover {
      font-size: px-to-rem(10);
      padding: px-to-rem(10) px-to-rem(20) px-to-rem(9);
    }
  }
  @include mod(full) {
    max-width: 100%;
    width: 100%;
  }
  @include mod(hero) {
    background-color: transparent;
    border: 0 !important;
    color: $white;
    font-size: px-to-rem(11);
    padding: 0;
    &:hover {
      background-color: transparent;
    }
  }
  @include mod(more) {
    @include more-chevron-animation();
  }
  @include mod(less) {
    @include less-chevron-animation();
  }
  @include mod(next) {
    @include after-chevron-animation();
  }
  @include mod(prev) {
    @include before-chevron-animation();
  }
  @include mod(textual) {
    padding: 0;
    &:hover {
      padding: 0;
    }
  }
  @include mod(action) {
    > svg {
      backface-visibility: hidden;
      border-radius: px-to-rem(16);
      border: 1px solid $brand-color;
      display: inline-block;
      font-size: px-to-rem(6);
      height: px-to-rem(16);
      line-height: 1.6;
      margin: -0.4rem 0.4rem 0 0;
      transform: translateX(-(px-to-rem(3)));
      transition: transform .5s;
      vertical-align: -30% !important;
      width: px-to-rem(16);
    }

  }
}
@include block(callout-link) {
  @include block(separator) {
    background-color: rgba($white, .3);
    display: block;
    height: 1px;
    margin-bottom: px-to-rem(16);
    width: px-to-rem(150);
    @include mod(dark) {
      background-color: $light-blue-grey;
    }
    @include media-breakpoint-up(phone) {
      margin-bottom: px-to-rem(24);
    }
    @include mod(centered) {
      @include auto-center();
    }
  }
  @include media-breakpoint-up(tablet) {
    display: block;
  }

}
@include block(paypal-payment-button) {
  margin: 0 auto;
}
