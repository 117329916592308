@include block(form) {
  margin: px-to-rem(24) 0 px-to-rem(16) 0;
}

form + h2 {
  margin-top: px-to-rem(40);
}

@include block(property) {
  margin-bottom: px-to-rem(24);
  @include elem(name) {
    animation: .75s slideInRight;
    color: $alt-text-color;
    display: block;
    font: {
      size: px-to-rem(9);
      weight: $font-weight-bold;
    }
    margin-bottom: px-to-rem(4);
    overflow: hidden;
    text: {
      overflow: ellipsis;
      transform: uppercase;
    }
    transition: opacity .5s, transform .5s;
    white-space: nowrap;
  }
  @include elem(error) {
    color: $red;
    display: block;
    margin-top: px-to-rem(6);
    font: {
      size: px-to-rem(9);
      weight: $font-weight-bold;
    }
    text-transform: uppercase;
  }
  @include elem(info) {
    color: $brand-color;
    display: block;
    margin-top: px-to-rem(6);
    font: {
      size: px-to-rem(9);
      weight: $font-weight-bold;
    }
    text-transform: uppercase;
    a {
      &:hover, &:active, &:visited {
        color: $brand-color;
      }
    }
  }
  @include elem(value) {
    @include inputFull();
    display: inline-block;
    margin: 0;
    max-width: 100%;
    position: relative;
    @include mod(select) {
      &::after {
        background: transparent url('data:image/svg+xml;utf8,<svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><path d="M15 17l-4-4h8" fill-rule="evenodd"></path></svg>') no-repeat center;
        background-size: px-to-rem(24) px-to-rem(24);
        content: '';
        display: block;
        height: px-to-rem(24);
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        right: px-to-rem(8);
        text-indent: 200%;
        top: 50%;
        transform: translate(0, -50%);
        white-space: nowrap;
        width: px-to-rem(24);
        z-index: 1;
      }
    }
    @include mod(error) {
      @include inputErrorIcon();
      > *[class^="property__"] {
        border-bottom: 2px solid $red;
      }
    }
  }
  @include elem(text-box) {
    @include input();
  }
  @include elem(search-text-box) {
    @include input();
    @include heading-display(h1);
    border: 0;
    border-bottom: 1px solid $border-color;
    font-weight: $font-weight-normal;
    height: auto;
    padding: 0;
    &:focus {
      border-bottom: 1px solid $brand-color;
      outline: none;
    }
  }
  @include elem(select) {
    @include input();
    box-sizing: border-box;
    cursor: pointer;
    padding: 0 px-to-rem(32) 0 px-to-rem(16);
  }
  @include elem(icon-inside) {
    color: $icon-darkgrey !important;
    cursor: pointer;
    position: absolute;
    top: 22%;
    right: px-to-rem(10);
    svg {
      height: px-to-rem(24);
      width: px-to-rem(24);
    }
  }
  @include elem(checkbox) {
    display: inline-block;
    font-size: px-to-rem(14);
    @include elem(label) {
      background-color: $white;
      border: 0;
      color: $main-text-color;
      cursor: pointer;
      display: inline-block;
      font-size: px-to-rem(14);
      line-height: 1;
      padding: {
        left: px-to-rem(24);
        right: px-to-rem(12);
        top: px-to-rem(3);
      }
      position: relative;
      text-align: left;
      vertical-align: text-top;
      @include mod(readonly) {
        color: $light-text-color;
      }
    }
    @include elem(box) {
      background-color: $white;
      border: 1px solid $input-border;
      display: block;
      height: 1.23077em;
      left: 0;
      position: absolute;
      text-align: center;
      top: 1px;
      transition: background-color 200ms, border-color 200ms;
      user-select: none;
      width: 1.23077em;
      z-index: 1;
    }
    @include elem(input) {
      background-color: rgb(255, 255, 255);
      border: 0;
      height: 1.23077em;
      left: 0;
      outline-color: rgb(212, 212, 212);
      position: absolute;
      top: 1px;
      -webkit-appearance: none;
      width: 1.23077em;
      &:checked + span {
        background: $brand-color url('data:image/svg+xml;charset=UTF-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%20fill%3D%22%23fff%22%3E%3Cpath%20d%3D%22M32%2C4L12%2C32L0%2C20l4-4l8%2C8L28%2C0L32%2C4z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E') no-repeat center center;
        background-size: px-to-rem(7);
      }
    }
  }
  @include mod(footer) {
    border-top: 1px solid $border-color;
    margin-top: px-to-rem(40);
    padding-top: px-to-rem(40);
  }
  @include mod(full) {
    max-width: 100%;
    width: 100%;
    @include block(property) {
      @include elem(name) {
        max-width: 100%;
        width: 100%;
      }
      @include elem(value) {
        max-width: 100%;
        width: 100%;
      }
      @include elem(text-box) {
        max-width: 100%;
        width: 100%;
      }
      @include elem(search-text-box) {
        max-width: 100%;
        width: 100%;
      }
      @include elem(select) {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  hr {
    border-top: 1px solid $border-color;
    margin: {
      bottom: px-to-rem(20);
      top: px-to-rem(20);
    }
  }
  @include mod(divider) {
    margin: px-to-rem(50) 0;
  }
  @include elem(group) {
    margin: px-to-rem(24) 0;
    padding-bottom: px-to-rem(16);
    @include block(property) {
      margin-bottom: px-to-rem(8);
    }
  }
}

input, select {
  @include antialias-font();
}

@include block(button-property) {
  align-items: center;
  border: 1px solid $border-color;
  display: flex;
  justify-content: center;
  padding: px-to-rem(16) px-to-rem(24);
  transition: border-color .2s, box-shadow .2s, background-color .2s, color .2s;
  @include mod(selected) {
    border: 1px solid $brand-color;
    box-shadow: 0 0 0 1px $brand-color inset;
  }
  @include mod(new) {
    border: 1px dashed $border-color;
  }
  @include elem(icon) {
    height: 4em !important;
    width: 4em !important;
  }
  @include elem(value) {
    display: inline-block;
    margin: 0 px-to-rem(14) 0 0;
    vertical-align: top;
  }
  @include elem(label) {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
  }
  @include elem(title) {
    font: {
      size: px-to-rem(16);
      weight: $font-weight-normal;
    }
    line-height: 1.1;
  }
  @include elem(description) {
    color: $alt-text-color;
    font: {
      size: px-to-rem(12);
    }
    line-height: 1.3;
    margin-top: px-to-rem(6);
  }
  @include mod(disabled) {
    background-color: rgba($light-border-color, .4);
    border: 1px solid $light-border-color;
    @include block(button-property) {
      @include elem(label) {
        cursor: not-allowed;
      }
      @include elem(title) {
        color: rgba($alt-text-color, .6);
      }
      @include elem(description) {
        color: rgba($light-text-color, .6);
      }
    }
  }

}
