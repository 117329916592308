@include block(poster) {
  position: relative;
  @include elem(image) {
    @include cover();
    height: 25vh;
    @include media-breakpoint-up(tablet) {
      height: 30vh;
    }
    @include media-breakpoint-up(desktop) {
      height: 40vh;
    }
    @include media-breakpoint-up(desktop) {
      height: 50vh;
    }
  }
  @include elem(container) {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    text-align: center;
    @include media-breakpoint-up(tablet) {
      flex-direction: column;
      padding: px-to-rem(64);
    }
  }
  @include elem(claim) {
    z-index: 20;
  }
  @include elem(heading) {
    color: $white;
    font: {
      family: $font-family-main;
      size: calc(#{px-to-rem(16)} + 3vw);
      weight: $font-weight-bold;
    }
    line-height: 1.2;
    letter-spacing: -#{px-to-rem(1)};
    a {
      color: $white;
    }
  }
  @include elem(description) {
    @include paragraph();
    color: white;
  }
}
