@include block(breadcrumbs) {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: px-to-rem(40);
  @include elem(item) {
    margin: 0 px-to-rem(6);
    a {
      @include default-link($alt-text-color);
      font: {
        size: px-to-rem(12);
        weight: $font-weight-semibold;
      }
    }
    svg {
      color: $alt-text-color;
      margin-left: px-to-rem(10);
      width: px-to-rem(8);
    }
    @include mod(active) {
      a {
        color: $main-text-color !important;
        cursor: default;
      }
    }
  }
}
