@include block(list) {
  display: block;
  @include mod(container) {
    @include container();
  }
  @include elem(item) {
    border-bottom: 1px solid $divider;
    margin-bottom: px-to-rem(40);
    padding-bottom: px-to-rem(40);
    //&:last-of-type {
    //  border-bottom: 0;
    //}
    @include media-breakpoint-up(tablet) {
      margin-bottom: px-to-rem(48);
      padding-bottom: px-to-rem(48);
    }

    @include elem(container) {
      border: 0 !important;
      display: flex;
      width: 100%;
      height: auto !important;
    }
    @include elem(info) {
      @include flex-width(7, desktop);
      @include media-breakpoint-down(tablet) {
        order: 1;
      }
    }
    @include elem(image) {
      @include flex-with-margin(5, 1, left, desktop);
      display: flex;
      justify-content: flex-end;
      overflow: hidden;
      position: relative;
      @include media-breakpoint-down(tablet) {
        margin-bottom: px-to-rem(18) !important;
      }
      > figure {
        @include cover();
        max-width: 100%;
        min-height: px-to-rem(240);
        height: auto;
        width: 100%;
        @include media-breakpoint-down(tablet) {
          min-height: px-to-rem(352);
        }
        @include media-breakpoint-down(phone) {
          min-height: px-to-rem(250);
        }
      }
    }
    @include elem(eyebrow) {
      @include eyebrow();
      font-size: px-to-rem(11) !important;
      > p {
        @include eyebrow();
        color: $brand-color;
        @include media-breakpoint-down(tablet) {
          display: none;
        }
      }
    }
    @include elem(sponsored-logo) {
      align-items: center;
      display: flex;
      font-size: px-to-rem(10);
      span {
        color: $light-text-color;
      }
      img {
        max-height: 100%;
        max-width: 100%;
        margin-left: px-to-rem(10);
        width: px-to-rem(110);
        @include media-breakpoint-down(tablet) {
          width: px-to-rem(70);
        }
      }
    }
    @include elem(title) {
      @include heading(h3);
      color: $main-text-color;
      line-height: 1.4;
      padding-bottom: px-to-rem(8);
    }
    @include elem(description) {
      @include smallParagraph();
    }
    @include elem(additionals-infos) {
      @include eyebrow();
      color: $light-text-color;
      padding-top: px-to-rem(18);
      @include media-breakpoint-down(tablet) {
        display: none;
      }
    }
  }
  @include elem(button) {
    margin-top: px-to-rem(32);
  }
}

@include block(slender-list) {
  @include block(list) {
    @include elem(wrapper) {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      &:after {
        @include flex-width(5.75, tablet);
        @include flex-width(3.75, hires);
        content: '';
        flex: auto;
      }
    }
    @include elem(item) {
      @include flex-width(12);
      @include flex-width(5.75, tablet);
      @include flex-width(3.75, hires);
      align-items: center;
      display: flex;
      margin-bottom: px-to-rem(24);
      padding-bottom: px-to-rem(24);
      &:nth-last-child(-n+2) {
        @include media-breakpoint-up(tablet) {
          border-bottom: 0
        }
      }
      &:nth-last-child(-n+3) {
        @include media-breakpoint-up(hires) {
          border-bottom: 0
        }
      }
      @include elem(image) {
        @include flex-width(4.5);
        @include flex-width(3.5, tablet);
        @include flex-width(4.5, hires);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0 !important;
        @include elem(picture) {
          @include cover();
          width: px-to-rem(126);
          height: px-to-rem(80);
        }
        @include elem(number) {
          @include numberMarker(solid);
          transform: translateX(-50%);
        }
        @include elem(spacer) {
          display: inline-block;
          height: px-to-rem(25);
          line-height: px-to-rem(25);
          position: relative;
          text-align: center;
          width: px-to-rem(22);
          z-index: 1;
        }
      }
      @include elem(info) {
        display: flex;
        flex-direction: column;
      }
      @include elem(title) {
        @include heading(h6, false);
        color: $main-text-color;
        line-height: 1.4;
        margin: 0;
        padding: 0;

      }
      @include elem(additionals-infos) {
        display: block;
        margin: 0;
        padding: 0;
      }
    }
  }
  + .callout-link {
    margin-top: px-to-rem(32);
    text-align: center;
    .separator {
      @include auto-center();
    }
  }
}

@include block (narrow-list) {
  @include block(list) {
    @include elem(wrapper) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    @include elem(item) {
      @include flex-width(12);
      @include flex-width(5.75, wide);
      align-items: center;
      display: flex;
      margin-bottom: px-to-rem(24);
      padding-bottom: px-to-rem(24);
      &:last-of-type {
        @include media-breakpoint-down(desktop) {
          border-bottom: 0 !important;
        }
      }
      @include elem(container) {
        display: flex;
        flex-flow: row-reverse wrap;
        height: auto;
      }
      @include elem(image) {
        @include flex-width(3.5);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0 !important;
        @include elem(picture) {
          @include cover();
          width: px-to-rem(126);
          height: px-to-rem(80);
        }
        @include elem(number) {
          display: none !important;
          @include numberMarker(solid);
          transform: translateX(-50%);
        }
        @include elem(spacer) {
          display: inline-block;
          height: px-to-rem(25);
          line-height: px-to-rem(25);
          position: relative;
          text-align: center;
          width: px-to-rem(22);
          z-index: 1;
        }
      }
      @include elem(info) {
        display: flex;
        flex-direction: column;
        line-height: 1.5 !important;
      }
      @include elem(title) {
        @include heading(h6, false);
        color: $main-text-color;
        margin: 0;
        padding: 0;
      }
      @include elem(additionals-infos) {
        display: block;
        margin: 0;
        padding: 0;
      }
      @include elem(place) {
        @include eyebrow();
        color: $light-text-color;
        display: block;
        margin: 0;
        padding: 0;
      }
      @include elem(ttd) {
        color: $light-text-color;
        font: {
          family: $font-family-secondary;
          size: px-to-rem(15);
          style: italic;
          weight: $font-weight-normal;
        }
      }
    }
    @include elem(button) {
      @include media-breakpoint-down(desktop) {
        @include auto-center();
        text-align: center;
        @include block(separator) {
          @include auto-center();
        }
      }
    }
  }
  + .callout-link {
    margin-top: px-to-rem(32);
    text-align: center;
    .separator {
      @include auto-center();
    }
  }
  @include media-breakpoint-down(desktop) {
    margin-bottom: px-to-rem(64);
  }
}

@include block(place-list) {
  @include media-breakpoint-up(desktop) {
    padding-right: px-to-rem(32);
  }
}

@include block(order-list) {
  margin: 0 auto px-to-rem(16);
  max-width: px-to-rem(1024);
  width: 100%;
  @include media-breakpoint-down(desktop) {
    font-size: smaller;
  }
  @include elem(item) {
    align-items: center;
    border: 1px solid $border-color;
    color: $main-text-color;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: px-to-rem(16);
    padding: px-to-rem(24) px-to-rem(16);
    transition: border-color .2s, box-shadow .2s;
    &:hover {
      border-color: $brand-color;
      box-shadow: 0 0 0 1px $brand-color inset;
    }
    @include elem(cell) {
      padding: 0 px-to-rem(16);
      text-align: left;
      min-width: 15%;
      max-width: 25%;
      @include media-breakpoint-down(phone) {
        &:last-of-type {
          display: none;
        }
      }
    }
    @include elem(label) {
      @include eyebrow();
      color: $light-grey;
      margin-bottom: 0;
    }
  }
  @include elem(info) {
    font: {
      size: px-to-rem(14);
    }
    margin: px-to-rem(48) 0 px-to-rem(16);
    text-align: left;
    strong {
      color: $main-text-color;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}

.fade-enter, .fade-leave-to {
  opacity: 0
}

.list__item {
  transition: all .5s;
}

.list-enter-active, .list-leave-active {
  transition: all .5s;
}

.list-enter, .list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
