@include block(food-n-drink) {
  min-height: px-to-rem(425);
  img {
    display: none;
    @include media-breakpoint-up(phone) {
      @include auto-vertical();
      @include fullscreen();
      display: block;
      width: 33.33%;
      z-index: -1;
    }
    @include media-breakpoint-up(hires) {
      top: -5rem;
    }
  }
  @include elem(container) {
    @include media-breakpoint-up(phone) {
      margin: {
        bottom: px-to-rem(60);
        left: 33.33%;
      }
      padding-left: px-to-rem(30);
    }
  }
  @include elem(list) {
    list-style: none;
    margin-bottom: px-to-rem(60);
    @include media-breakpoint-up(desktop) {
      overflow: hidden;
    }
  }
  @include elem(item) {
    border-bottom: 1px solid $border-color;
    &:first-child {
      border-top: 1px solid $border-color;
    }
    @include media-breakpoint-up(desktop) {
      float: left;
      width: calc(50% - #{px-to-rem(30)});
      &:nth-child(2) {
        border-top: 1px solid $border-color;
      }
      &:nth-child(2n) {
        margin-left: px-to-rem(30);
      }
    }
    @include elem(link) {
      display: block;
      padding: {
        bottom: px-to-rem(16);
        top: px-to-rem(21);
      }
      transition: transform .4s;
    }
    @include elem(title) {
      @include list-maintext();
      span {
        color: $alt-text-color;
      }
    }
    @include elem(category) {
      @include list-subtext();
      text-transform: uppercase;
    }
    &:hover {
      @include block(food-n-drink) {
        @include elem(item) {
          @include elem(link) {
            transform: translateX(px-to-rem(10));
          }
          @include elem(title) {
            color: $link-color;
          }
        }
      }
    }
  }
  @include elem(button) {
    text-align: center;
  }
}
