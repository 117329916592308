@keyframes fly-in {
  0% {
    opacity: 0;
    transform: translate(-25rem);
    width: 150%;
  }
  to {
    opacity: 1;
    transform: translate(0);
    width: 150%;
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(1.3);
  }
  10% {
    transform: scale(1);
  }
  15% {
    transform: scale(1.3);
  }
  20% {
    transform: scale(1);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}

@keyframes zoomScale {
  0% {
    transform: scale(1.2) rotate(.02deg);
  }
  to {
    transform: scale(1) rotate(.02deg);
  }
}

@keyframes layerBehind {
  0% {
    z-index: 100;
  }
  to {
    z-index: 0;
    visibility: hidden;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes raiseIn {
  0% {
    transform: translateY(px-to-rem(16));
  }
  to {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(px-to-rem(40));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(px-to-rem(20));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    opacity: .3;
  }
  to {
    opacity: 1
  }
}

.fadeshow-enter-active {
  opacity: 1;
  transition: opacity 2s ease;
}

.fadeshow-enter {
  opacity: 0;
  transition: opacity 2s ease;
}

@include block(lazy) {
  @include elem(loading) {
    opacity:0;
  }
  @include elem(loaded) {
    transition: opacity 1s;
  }
}
