@include block(cookie-consent) {
  background-color: $alt-dark-background;
  margin-bottom: 0;
  max-height: px-to-rem(120);
  transition: max-height .4s;
  text-align: center;
  padding: 0 px-to-rem(20);
  @include elem(inner) {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: px-to-rem(14);
    justify-content: space-between;
    padding: px-to-rem(22) 0;
  }
  @include elem(message) {
    color: $white !important;
    text-align: center;
    width: 100%;
  }
  @include elem(link) {
    color: $white;
    text-decoration: underline;
  }
  @include elem(button) {
    background-color: $black;
    border: 1px solid $black;
    font-size: 10px;
    padding: px-to-rem(12) px-to-rem(30) px-to-rem(10);
    &:hover {
      background-color: color_mixer($black, -15);
    }
  }
}
