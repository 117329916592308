.slick-slider {
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  display: block;
  position: relative;
  touch-action: pan-y;
  user-select: none;
}

.slick-list {
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider {
  .slick-track,
  .slick-list {
    transform: translate3d(0, 0, 0);
  }
}

.slick-track {
  @include clearfix(table);
  display: block;
  left: 0;
  position: relative;
  top: 0;
}

@include block(slick-slide) {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  img {
    display: block;
  }
  &.slick-loading {
    img {
      display: none;
    }
  }
  &.dragging {
    img {
      pointer-events: none;
    }
  }
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-initialized {
  .slick-slide {
    display: block;
  }
}

.slick-loading {
  .slick-slide {
    visibility: hidden;
  }

  .slick-track {
    visibility: hidden;
  }
}

.slick-vertical {
  .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow {
  .slick-hidden {
    display: none;
  }
}

@include block(sweet-content) {
  text-align: left !important;
}

@include block(sweet-action-close) {
  &:hover {
    background-color: transparent !important;
    color: $main-text-color !important;
  }
}

@include block(v--modal) {
  border-radius: 0 !important;
  box-shadow: 2px 2px px-to-rem(20px) 1px;
  @include media-breakpoint-down(tablet) {
    width: auto !important;
  }
}

@include block(v--modal-overlay) {
  background-color: rgba($black, .5) !important;
}

@include block(carousel) {
  &.nobtns {
    padding: 0 px-to-rem(48);
    @include media-breakpoint-down(tablet) {
      padding: 0 px-to-rem(32);
    }
    @include block(slick-track) {
      transform: translate3d(0, 0, 0) !important;
    }
    @include block(slick-slide) {
      width: 25%;
      @include media-breakpoint-down(tablet) {
        width: 50%;
        &:nth-child(2n+1) {
          clear: both;
        }
      }
    }
  }
}

@include block(half-circle-spinner) {
  > .circle {
    border-width: 3px !important;
  }

}
