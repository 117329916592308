@font-face {
    font: {
        family: 'Benton Sans';
        style: normal;
        stretch: normal;
        weight: $font-weight-light;
    }
    src: url(/fonts/2c8c3478-e1ba-4af3-bfd0-9fea259fc17f-2.eot);
    src: url(/fonts/2c8c3478-e1ba-4af3-bfd0-9fea259fc17f-2.eot?) format('embedded-opentype'),
    url(/fonts/2c8c3478-e1ba-4af3-bfd0-9fea259fc17f-3.woff) format('woff'),
    url(/fonts/2c8c3478-e1ba-4af3-bfd0-9fea259fc17f-1.ttf) format('truetype');
}

@font-face {
    font: {
        family: 'Benton Sans';
        style: normal;
        stretch: normal;
        weight: $font-weight-semibold;
    }
    src: url(/fonts/19319132-31a6-45e2-85a5-6dacae897490-2.eot);
    src: url(/fonts/19319132-31a6-45e2-85a5-6dacae897490-2.eot?) format('embedded-opentype'),
    url(/fonts/19319132-31a6-45e2-85a5-6dacae897490-3.woff) format('woff'),
    url(/fonts/19319132-31a6-45e2-85a5-6dacae897490-1.ttf) format('truetype');
}

@font-face {
    font: {
        family: 'Benton Sans';
        style: normal;
        stretch: normal;
        weight: $font-weight-bold;
    }
    src: url(/fonts/743d3d3a-da58-48d2-a5c3-bd7994650e23-2.eot);
    src: url(/fonts/743d3d3a-da58-48d2-a5c3-bd7994650e23-2.eot?) format('embedded-opentype'),
    url(/fonts/743d3d3a-da58-48d2-a5c3-bd7994650e23-3.woff) format('woff'),
    url(/fonts/743d3d3a-da58-48d2-a5c3-bd7994650e23-1.ttf) format('truetype');
}

@font-face {
    font: {
        family: 'Miller Daily';
        style: normal;
        stretch: normal;
        weight: $font-weight-normal;
    }
    src: url(/fonts/6e603ae4-800b-4625-9fa8-1819315263a6-2.eot);
    src: url(/fonts/6e603ae4-800b-4625-9fa8-1819315263a6-2.eot?) format('embedded-opentype'),
    url(/fonts/6e603ae4-800b-4625-9fa8-1819315263a6-3.woff) format('woff'),
    url(/fonts/6e603ae4-800b-4625-9fa8-1819315263a6-1.ttf) format('truetype');
}

@font-face {
    font: {
        family: 'Miller Daily';
        style: italic;
        stretch: normal;
        weight: $font-weight-normal;
    }
    src: url(/fonts/2d4b6287-e087-4bce-89af-61a0cd634f27-2.eot);
    src: url(/fonts/2d4b6287-e087-4bce-89af-61a0cd634f27-2.eot?) format('embedded-opentype'),
    url(/fonts/2d4b6287-e087-4bce-89af-61a0cd634f27-3.woff) format('woff'),
    url(/fonts/2d4b6287-e087-4bce-89af-61a0cd634f27-1.ttf) format('truetype');
}

@import url('https://fonts.googleapis.com/css?family=Caveat');
