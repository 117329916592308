@import './../lib/index';

@include block(article) {
  background-color: white;
  @include elem(container) {
    @include container();
    padding-bottom: px-to-rem(30);
    padding-top: px-to-rem(44);
    @include media-breakpoint-up(tablet) {
      padding-top: px-to-rem(120);
    }
  }
  @include elem(intro) {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: px-to-rem(34);
  }
  @include elem(header) {
    display: flex;
    flex-direction: column-reverse;
    @include elem(container) {
      @include flex-width(9, tablet);
      @include breakpoint-ml-auto(tablet);
    }
  }
  @include elem(subheader) {
    margin-top: px-to-rem(30);
  }
  @include elem(title) {
    @include heading(h1);
  }
  @include elem(inspiration) {
    @include heading(h6);
    font-size: px-to-rem(13);
    margin-bottom: px-to-rem(15);
    text-transform: uppercase;
    a {
      color: $red;
    }
  }
  @include elem(sponsored-logo) {
    align-items: center;
    color: $light-text-color;
    display: flex;
    font-size: px-to-rem(11);
    img {
      max-height: 100%;
      max-width: 100%;
      margin-left: px-to-rem(10);
      width: px-to-rem(78);
      @include media-breakpoint-down(tablet) {
        width: px-to-rem(60);
      }
    }
  }
  @include elem(authors) {
    border-bottom: 1px solid $border-color;
    float: left;
    margin-bottom: px-to-rem(20);
    padding-bottom: px-to-rem(20);
    width: 100%;
    @include media-breakpoint-up(tablet) {
      border: {
        bottom: 0;
        right: 1px solid $border-color;
      }
      width: 25%;
    }
    @include media-breakpoint-up(desktop) {
      width: 20%;
    }
    &:before {
      background-color: $red;
      content: '';
      display: block;
      height: px-to-rem(2);
      width: px-to-rem(48);
    }
  }
  @include elem(author) {
    align-items: baseline;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @include elem(container) {
      align-items: baseline;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: px-to-rem(16);
    }
    @include elem(name) {
      color: inherit;
      font: {
        size: px-to-rem(12);
        weight: $font-weight-bold;
      }
      line-height: 1;
      margin: {
        right: px-to-rem(8);
        top: px-to-rem(10);
      }
    }
    @include elem(title) {
      color: $alt-text-color;
      font-size: px-to-rem(12);
      line-height: 1;
      margin: {
        right: px-to-rem(8);
        top: px-to-rem(8);
      }
    }
    @include elem(panel) {
      border: {
        top: 1px solid $divider;
      }
      padding: px-to-rem(48) 0 0;
      text-align: center;
      > * {
        margin-top: 0 !important;
      }
      @include elem(name) {
        font-size: px-to-rem(24);
        margin-top: px-to-rem(24) !important;
      }
      @include elem(avatar) {
        align-items: center;
        display: flex;
        justify-content: center;
      }
      @include elem(icon) {
        margin: {
          bottom: px-to-rem(20);
          top: 0 !important;
        }
        text-align: center;
        .icon {
          height: px-to-rem(32);
          width: px-to-rem(32);
        }
      }
      @include elem(biography) {
        font: {
          size: px-to-rem(18);
          style: italic;
        }
        margin-top: px-to-rem(12) !important;
      }
      @include elem(social) {
        margin-top: px-to-rem(20) !important;
        @include elem(link) {
          border-bottom: none !important;
          text-decoration: none;
          margin: 0 px-to-rem(8);
        }
      }
    }
  }
  @include elem(social-share) {
    float: left;
    margin-left: px-to-rem(20);
    @include elem(text) {
      color: $alt-text-color;
      font: {
        size: px-to-rem(10);
        weight: $font-weight-bold;
      }
      margin-bottom: px-to-rem(6);
      text-transform: uppercase;
    }
  }
  @include elem(content) {
    @include flex-width(9, tablet);
    @include breakpoint-ml-auto(tablet);
  }
  @include elem(body) {
    max-width: inherit;
    position: relative;
    @include media-breakpoint-up(tablet) {
      padding-bottom: px-to-rem(58);
    }
    @include media-breakpoint-up(wide) {
      max-width: px-to-rem($article-max-width);
    }
    a {
      border-bottom: 1px dashed $brand-color;
      color: $brand-color;
    }
    > * {
      margin: {
        top: px-to-rem(18);
      }
      @include media-breakpoint-up(phone) {
        margin: {
          top: px-to-rem(24);
        }
      }
    }
    @include elem(content) {
      @include elem(title) {
        @include heading(h4);
        margin: {
          top: px-to-rem(70);
        }
        @include media-breakpoint-up(tablet) {
          margin: {
            top: px-to-rem(90);
          }
        }
      }
      @include elem(text) {
        @include paragraph();
        font-family: $font-family-secondary;
        > * {
          margin: {
            left: auto;
            right: auto;
            top: px-to-rem(24);
          }
        }
      }
      @include elem(image-container) {
        margin: {
          bottom: px-to-rem(32) !important;
          top: px-to-rem(32) !important;
        }
        @include media-breakpoint-up(phone) {
          margin: {
            bottom: px-to-rem(66) !important;
            top: px-to-rem(66) !important;
          }
        }
        @include media-breakpoint-up(desktop) {
          margin-left: -10%;
          width: 120%;
        }
        @include media-breakpoint-up(wide) {
          margin-left: -20%;
          width: 140%;
        }
      }
      @include elem(embed) {
        margin: {
          bottom: px-to-rem(84);
          top: px-to-rem(84);
        }
      }
      @include elem(product) {
        align-items: center;
        box-shadow: 0 px-to-rem(3) px-to-rem(28) 0 rgba($black, .08);
        display: flex;
        margin: px-to-rem(84) px-to-rem(60);
        @include media-breakpoint-up(phone) {
          padding: px-to-rem(28) px-to-rem(34);
        }
        @include elem(media) {
          @include flex-width(3);
          float: left;
        }
        @include elem(content) {
          @include flex-with-margin(9, .5, left);
          letter-spacing: -0.25px;
        }
        @include elem(heading) {
          color: $main-text-color;
          font: {
            size: px-to-rem(map_get($heading-sizes, h2));
            weight: $font-weight-light;
          }
          letter-spacing: -.3px;
          line-height: 1;
          margin: 0;
        }
        @include elem(additional) {
          color: $light-text-color;
          font: {
            size: px-to-rem(map_get($heading-sizes, h5));
            weight: $font-weight-light;
          }
          margin-bottom: px-to-rem(12);
        }
        @include elem(description) {
          color: $alt-text-color;
          font: {
            size: px-to-rem(map_get($heading-sizes, h6));
            weight: $font-weight-light;
          }
          line-height: 1.4;
        }
        @include block(button) {
          margin-top: px-to-rem(24);
        }
      }
    }
  }
  @include elem(feature) {
    > p {
      @include feature();
      margin-top: 0 !important;
    }
  }
  @include elem(image) {
    display: block;
    margin: {
      bottom: px-to-rem(16);
      left: -5%;
      right: -5%;
    }
    max-width: none;
    width: 110%;
    @include media-breakpoint-up(tablet) {
      margin-left: -7%;
      margin-right: -7%;
      width: 114%;
    }
    @include media-breakpoint-up(desktop) {
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
      width: 100%;
    }
    @include elem(caption) {
      @include caption();
      display: block;
    }
  }
  @include elem(list) {
    @include list();
    padding-left: px-to-rem(20);
  }
  @include elem(link) {
    @include link();
  }
  @include elem(footer) {
    margin-top: px-to-rem(60);
    max-width: px-to-rem($article-max-width);
  }
  @include elem(date) {
    @include date();
    margin: {
      bottom: px-to-rem(70);
      top: px-to-rem(70);
    }
  }
  @include elem(chips) {
    display: block;
    margin-bottom: px-to-rem(60);
  }
  @include elem(ad) {
    margin: {
      bottom: px-to-rem(50);
      top: px-to-rem(42);
    }
    @include media-breakpoint-up(tablet) {
      margin: {
        bottom: px-to-rem(90);
        top: px-to-rem(38);
      }
    }
  }
  @include elem(related) {
    border-top: 1px solid $divider;
    padding: {
      top: px-to-rem(60);
    }
    @include media-breakpoint-down(tablet) {
      padding: {
        left: px-to-rem(32);
        right: px-to-rem(32);
      }
    }
    @include media-breakpoint-up(desktop) {
      padding: {
        top: px-to-rem(80);
      }
    }
  }
  @include elem(advertorial) {
    @include elem(panel) {
      border: {
        top: 1px solid $divider;
      }
      padding: px-to-rem(48) 0 px-to-rem(32);
      text-align: center;
      > * {
        margin-top: 0 !important;
      }
      @include elem(logo) {
        align-items: center;
        display: flex;
        justify-content: center;
        a {
          border-bottom: none !important;
        }
      }
      @include elem(description) {
        font: {
          family: $font-family-main !important;
          size: px-to-rem(15);
        }
        line-height: 1.6;
        margin-top: px-to-rem(28) !important;
      }
    }
  }
}
