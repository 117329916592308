@include block(or-separator) {
  border-bottom: 1px solid $border-color;
  height: px-to-rem(10);
  text-align: center;
  width: 100%;
  span {
    background-color: $white;
    font: {
      size: px-to-rem(10);
      weight: $font-weight-bold;
    }
    padding: 0 px-to-rem(10);
    text-transform: uppercase;
  }
}

@include block(overlay) {
  @include fullscreen();
  background-color: rgba($black, .4);
  opacity: 0;
  position: fixed;
  transition: opacity .5s;
  z-index: 999;
  @include state(visible) {
    opacity: 1;
    visibility: visible;
  }
}

@include block(fs-overlay) {
  @include fullscreen();
  background-color: rgba($black, .4);
  opacity: 0;
  position: fixed;
  transition: opacity .4s;
  z-index: 999;
  @include mod(visible) {
    opacity: 1;
  }
}

@include block(gradient-overlay) {
  @include fullscreen();
  background-image: linear-gradient(to right top, $black, transparent 56%);
  opacity: .67;
  z-index: 1;
}

@include block(accordion) {
  @include elem(button) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 95%;
    svg {
      color: $alt-text-color;
      margin-top: -2px;
      width: px-to-rem(11);
    }
  }
  @include elem(wrapper) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  @include elem(item) {
    overflow: hidden;
    transition: max-height .5s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: px-to-rem(9999);
    @include mod(collapsed) {
      max-height: 0;
      transition: max-height .5s cubic-bezier(0, 1, 0, 1);
    }
  }
}

//@include block(callout-link) {
//  display: inline-block;
//  font-family: $font-family-main;
//  line-height: 1;
//  @include mod(aside) {
//    @include block(callout-link) {
//      @include elem(line) {
//        margin: {
//          left: 0;
//          right: -(px-to-rem(40));
//        }
//        width: calc(100% + #{px-to-rem(40)});
//      }
//    }
//  }
//  @include elem(line) {
//    background-color: $border-color;
//    height: 1px;
//    margin: {
//      bottom: px-to-rem(20);
//      left: -(px-to-rem(20));
//      right: -(px-to-rem(20));
//    }
//    width: calc(100% + #{px-to-rem(40)});
//  }
//  @include elem(link) {
//    @include after-chevron-animation();
//    color: $link-color;
//    text-decoration: none;
//    display: block;
//    font: {
//      size: px-to-rem(12);
//      weight: $font-weight-bold;
//    }
//    text-transform: uppercase;
//    transition: color .2s ease-in-out;
//  }
//}
