@include block(alert) {
  border-radius: 0;
  font-size: px-to-rem(14);
  margin-bottom: px-to-rem(32);
  padding: px-to-rem(15) px-to-rem(16) px-to-rem(12);
  @include mod(success) {
    background-color: $green;
    color: white;
  }
  @include mod(default) {

  }
  @include mod(alert) {
    background-color: $red;
  }
  @include mod(info) {
    background-color: $blue;
  }
  @include mod(warning) {
    background-color: $yella;
  }
}
