@include block(chip) {
  background-color: $white;
  border-radius: px-to-rem(16);
  border: 1px solid $border-color !important;
  color: $main-text-color !important;
  display: inline-block;
  font: {
    size: px-to-rem(14);
    weight: $font-weight-semibold;
  }
  height: px-to-rem(32);
  line-height: px-to-rem(32);
  margin: {
    bottom: px-to-rem(6);
    right: px-to-rem(6);
    top: px-to-rem(6);
  }
  padding: 0 px-to-rem(20);
  text-decoration: none !important;
  white-space: nowrap;
  > img {
    border-radius: 50%;
    float: left;
    margin: 0 px-to-rem(8) 0 -#{px-to-rem(22)};
    height: px-to-rem(32);
    width: px-to-rem(32);
  }
  @include mod(selected) {
    background-color: $dark-blue-grey;
    color: $white !important;
  }
  @include mod(small) {
    background-color: $white;
    border-radius: px-to-rem(16);
    border: 1px solid $border-color !important;
    color: $main-text-color !important;
    display: inline-block;
    font: {
      size: px-to-rem(10);
      weight: $font-weight-semibold;
    }
    height: px-to-rem(22);
    line-height: px-to-rem(22);
    margin: {
      bottom: px-to-rem(3);
      right: px-to-rem(3);
      top: px-to-rem(3);
    }
    padding: 0 px-to-rem(12);
    text-decoration: none !important;
  }
}


