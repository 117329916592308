@include block(catalog) {
  display: flex;
  @include elem(header) {
    padding-top: 0 !important;
    text-align: left !important;
    @include elem(title) {
      font: {
        size: px-to-rem(26);
        weight: $font-weight-light;
      }
      letter-spacing: -.5px;
      margin-bottom: px-to-rem(8);
    }
    @include elem(subtitle) {
      color: $alt-text-color;
      font: {
        size: px-to-rem(16);
        weight: $font-weight-light;
      }
      margin: 0 !important;
    }
  }
  @include elem(main) {
    @include flex-with-margin(9, .25, left, tablet);
    @include flex-with-margin(9.75, .25, left, wide);
    @include flex-order(2);
    @include mod('full') {
      flex: 1;
      margin: 0;
      max-width: 100%;
      width: 100% !important;
      > .product__grid {
        margin: 0;
      }
    }
  }
  @include elem(aside) {
    @include flex-width(3, tablet);
    @include flex-width(2.25, wide);
    @include flex-order(1);
    margin: 0 px-to-rem(16) 0 0;
    padding: 0 px-to-rem(16) 0 0;
    border-right: 1px solid $border-color;
    @include media-breakpoint-down(phone) {
      display: none;
    }
    @include elem(header) {
      @include eyebrow();
      color: $main-text-color;
      font: {
        size: px-to-rem(11) !important;
        weight: $font-weight-bold;
      }
      line-height: 1.1;
      margin: 0;
      padding: px-to-rem(10) px-to-rem(8);
    }
    @include elem(heading) {
      @include eyebrow();
      color: $main-text-color;
      font-size: px-to-rem(9) !important;
      margin-bottom: 0;
      padding: 0 px-to-rem(8);
    }
    @include elem(group) {
      margin: 0;
      padding: px-to-rem(4) 0;
      list-style: none;
    }
    @include elem(filter) {
      margin: px-to-rem(6) 0;
      padding: px-to-rem(3) px-to-rem(8) 0;
      color: $alt-text-color;
      font: {
        size: px-to-rem(12);
        weight: $font-weight-semibold;
      }
      a {
        @include default-link($alt-text-color);
        svg {
          margin-top: -2px;
          transform: rotate(45deg);
          width: px-to-rem(11);
        }
      }
      @include elem(chips) {
        margin-bottom: px-to-rem(14);
        &:first-of-type {
          margin-top: px-to-rem(6);
        }
        &:last-of-type {
          margin-bottom: px-to-rem(8);
        }
      }
      @include elem(chip) {
        background-color: $light-blue-grey;
        border-radius: px-to-rem(6);
        padding: px-to-rem(7) px-to-rem(6) px-to-rem(6);
      }
      @include elem(counter) {
        color: $light-text-color;
        font-size: px-to-rem(10);
      }
      @include mod(selected) {
        border-left: 2px solid $brand-color;
        color: $main-text-color;
        a {
          color: $main-text-color;
          font-weight: $font-weight-bold;
        }
      }
    }
  }
}

@include block(square) {
  @include elem(grid) {
    @include flex-negative-margin(.08, left);
    @include flex-negative-margin(.08, right);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    @include elem(item) {
      @include flex-width(6);
      @include flex-width(2.4, tablet);
      border: px-to-rem(10) solid $white;
      overflow: hidden;
      position: relative;
      text-align: center;
      &:hover {
        @include block(square) {
          @include elem(grid) {
            @include elem(item) {
              @include elem(image) {
                opacity: .5;
                transform: scale(1.1);
              }
            }
          }
        }
      }
      @include elem(link) {
        background-color: $image-background;
        display: block;
        transition: color .2s ease-in-out;
      }
      @include elem(image) {
        height: 100%;
        margin: 0;
        transition: transform 3s ease-out, opacity .5s;
        width: 100%;
      }
      @include elem(text) {
        color: $white;
        font: {
          size: px-to-rem(13);
          weight: $font-weight-bold;
        }
        left: 50%;
        margin: 0;
        padding: 0 px-to-rem(10);
        position: absolute;
        text-transform: uppercase;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
      }
    }
  }
}

@include block(bullet) {
  animation: pulse 3s infinite;
  background-color: $brand-color;
  border-radius: px-to-rem(10);
  display: inline-block;
  height: px-to-rem(10);
  line-height: px-to-rem(10);
  width: px-to-rem(10);
  @include mod('hotoffthepress') {
    background-color: $green;
  }
}
