@include block(footernav) {
  @include elem(container) {
    @include container();
    @include media-breakpoint-down(tablet) {
      margin: 0;
    }
  }
  @include elem(main) {
    border-top: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(desktop) {
      flex-direction: row;
      overflow: hidden;
      padding: {
        bottom: px-to-rem(60);
        top: px-to-rem(60);
      }
    }
    @include elem(links) {
      max-height: px-to-rem(49);
      overflow: hidden;
      transition: max-height .4s linear;
      vertical-align: top;
      @include media-breakpoint-up(desktop) {
        @include flex-width(2);
        max-height: px-to-rem(600);
      }
      &:not(:first-of-type) {
        @include media-breakpoint-down(tablet) {
          border-top: 1px solid $border-color;
        }
      }
    }
    @include elem(newsletter) {
      border-top: 0;
      max-height: none;
      @include media-breakpoint-up(desktop) {
        @include flex-order(1);
      }
      @include block(button) {
        margin-top: px-to-rem(20);
      }
    }
    @include elem(header) {
      color: $main-text-color;
      font: {
        size: px-to-rem(13);
        weight: $font-weight-bold;
      }
      line-height: inherit;
      margin-top: 0;
      @include media-breakpoint-down(tablet) {
        padding: px-to-rem(19) px-to-rem(15) px-to-rem(16);
      }
      @include media-breakpoint-up(desktop) {
        border: 0;
        margin-bottom: px-to-rem(10);
      }
      a {
        @include link-hover();
        color: currentColor;
        transition: {
          duration: .4s;
          property: color, border, background;
        }
      }
    }
    @include elem(wrapper) {
      @include media-breakpoint-down(tablet) {
        margin: 0 px-to-rem(15) px-to-rem(15);
      }
      @include block(footernav) {
        @include elem(main) {
          @include elem(text) {
            @include media-breakpoint-down(tablet) {
              margin-top: 0;
            }
          }
        }
      }
    }
    @include elem(text) {
      color: $alt-text-color;
      font: {
        size: px-to-rem(13);
      }
      line-height: 1.5;
      margin-top: px-to-rem(12);
    }
    @include elem(checkbox) {
      display: none;
      &:checked {
        + .footernav__main__links {
          max-height: px-to-rem(600);
          @include block(icon) {
            transform: rotateZ(180deg);
          }
        }
      }
    }
    @include block(accordion-target) {
      cursor: pointer;
      float: right;
      @include media-breakpoint-up(desktop) {
        display: none;
      }
      @include block(icon) {
        transition: transform .4s linear;
      }
    }
    @include elem(list) {
      @include media-breakpoint-down(tablet) {
        margin: 0 px-to-rem(15) px-to-rem(15);
      }
      @include elem(item) {
        font-size: px-to-rem(13);
        line-height: 2.2;
        list-style: none;
        @include media-breakpoint-up(desktop) {
          line-height: 1.85;
        }
        a {
          @include link-hover();
          color: $alt-text-color;
          display: block;
          transition-duration: .4s;
          transition-property: color, border, background;
        }
      }
    }
  }
  @include elem(bottom) {
    @include clearfix-only-after(table);
    border-top: 1px solid $border-color;
    padding: {
      bottom: px-to-rem(20);
      top: px-to-rem(30)
    }
    position: relative;
    @include media-breakpoint-up(desktop) {
      padding: {
        bottom: px-to-rem(50);
        left: px-to-rem(110);
        right: px-to-rem(141);
      }
    }
    @include elem(container) {
    }
    @include elem(nav) {
      font-size: px-to-rem(13);
      line-height: 1;
      margin-bottom: px-to-rem(15);
      @include media-breakpoint-up(desktop) {
        margin: 0;
      }
    }
    @include elem(list) {
      display: flex;
      flex-direction: column;
      @include media-breakpoint-up(desktop) {
        flex-direction: row;
      }
      @include elem(item) {
        border-bottom: 1px solid $border-color;
        font: {
          size: px-to-rem(13);
          weight: $font-weight-bold;
        }
        line-height: 1;
        list-style: none;
        @include media-breakpoint-up(desktop) {
          border: 0 !important;
          margin-right: px-to-rem(30);
          line-height: 1.85;
        }
        a {
          @include link-hover();
          color: $alt-text-color;
          display: block;
          @include media-breakpoint-down(tablet) {
            padding: px-to-rem(16) px-to-rem(15) px-to-rem(14);
          }
        }
      }
    }
    @include elem(logo) {
      height: px-to-rem(47);
      width: px-to-rem(93);
      @include media-breakpoint-down(tablet) {
        float: left;
        margin: {
          bottom: px-to-rem(15);
          left: px-to-rem(15);
        }
      }
      @include media-breakpoint-up(desktop) {
        left: 0;
        position: absolute;
        top: px-to-rem(17);
      }
    }
    @include elem(copyright) {
      clear: both;
      color: $alt-text-color;
      font: {
        size: px-to-rem(11);
      }
      line-height: 1.4;
      //margin-top: px-to-rem(20);
      text-align: center;
      @include media-breakpoint-down(tablet) {
        @include auto-center(px-to-rem(15));
        font-size: px-to-rem(11);
        line-height: 1.5;
        margin-top: px-to-rem(12);
      }
      @include media-breakpoint-up(desktop) {
        text-align: left;
      }
    }
    @include elem(social) {
      @include clearfix-only-after(table);
      font-size: px-to-rem(18);
      list-style: none;
      @include media-breakpoint-down(tablet) {
        @include auto-center(px-to-rem(15));
        float: right;
      }
      @include media-breakpoint-up(desktop) {
        position: absolute;
        right: 0;
        top: px-to-rem(20);
      }
      @include elem(item) {
        float: left;
        &:not(:first-child) {
          margin-left: px-to-rem(6);
        }
        > a {
          border: 1px solid $border-color;
          border-radius: 50%;
          color: $icon-grey;
          display: block;
          height: px-to-rem(40);
          line-height: px-to-rem(45);
          text-align: center;
          transition-duration: .4s;
          transition-property: color, border, background-color;
          width: px-to-rem(40);
          &:hover {
            background-color: $icon-grey;
            color: white !important;
          }
        }
      }
    }
  }

  //.bottom {
  //  @extend %cleartable;
  //  border-top: 1px solid $alphalines;
  //  overflow: hidden;
  //  padding-bottom: $gutter;
  //  position: relative;
  //  @include responsive-desktop() {
  //    padding: 2.6rem 14.1rem 8.1rem 11rem;
  //  }
  //  .bottom-logo {
  //    width: 9.3rem;
  //    @include responsive-tablet-max() {
  //      float: left;
  //      margin: {
  //        bottom: $gutter / 2;
  //        left: $gutter / 2;
  //      }
  //    }
  //    @include responsive-desktop() {
  //      left: 0;
  //      position: absolute;
  //      top: 2.5rem;
  //    }
  //    svg {
  //      color: rgba($footer-text-grey, .4);
  //      display: block;
  //      fill: currentColor;
  //      transition: color .2s;
  //    }
  //    &:hover {
  //      svg {
  //        color: $link-color;
  //      }
  //    }
  //  }
  //  .bottom-links {
  //    font: {
  //      size: 1.3rem;
  //    }
  //    line-height: 1;
  //    margin: {
  //      bottom: $gutter / 2;
  //    }
  //    @include responsive-desktop() {
  //      margin: 0;
  //    }
  //    .links-item {
  //      border-bottom: 1px solid $grey-lines;
  //      display: block;
  //      @include responsive-desktop() {
  //        border-bottom: 0;
  //        display: inline-block;
  //        &:not(:last-child) {
  //          margin-right: 2.1rem;
  //        }
  //      }
  //      .links-link {
  //        color: $footer-text-grey;
  //        display: block;
  //        font-weight: $font-weight-bold;
  //        transition: color .4s;
  //        @include responsive-tablet-max() {
  //          margin: 1.4rem ($gutter / 2);
  //        }
  //        &:hover {
  //          color: $link-color;
  //        }
  //      }
  //    }
  //  }
  //  .bottom-language {
  //    align-items: center;
  //    clear: both;
  //    display: flex;
  //    justify-content: center;
  //    @include responsive-tablet-max() {
  //      margin: {
  //        bottom: $gutter;
  //        left: $gutter / 2;
  //        right: $gutter / 2;
  //      }
  //    }
  //    @include responsive-desktop() {
  //      position: absolute;
  //      right: 0;
  //      top: 8rem;
  //      width: 22rem;
  //    }
  //    .bottom-language-label {
  //      color: $footer-text-grey;
  //      float: left;
  //      font-size: 1.3rem;
  //      line-height: 1;
  //      @include responsive-desktop() {
  //        padding-left: 1.2rem;
  //        width: 11rem;
  //      }
  //    }
  //    .bottom-language-select {
  //      appearance: none;
  //      background: {
  //        color: $white;
  //        image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAICAYAAACCuR0hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RjhGQzc5MkNGMDM4MTFFNDlCOTE5QTczMjQ3MjgzMTciIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RjhGQzc5MkRGMDM4MTFFNDlCOTE5QTczMjQ3MjgzMTciPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1MDA1QkRGRkYwMjQxMUU0OUI5MTlBNzMyNDcyODMxNyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1MDA1QkUwMEYwMjQxMUU0OUI5MTlBNzMyNDcyODMxNyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PiH5FUMAAAB7SURBVHjaYkwvqZnGwMCQyUAamA7EWSDGjO5mBnoCJiDOBeJNJOjZBNUzIADk4L9AHAnEJ4lQfxKq9u9AOhgEvgGxLxDfwaP2DlTNN4YBBExI7NdA7Aml0QE+uQFzMK5QJCb0B8zB6OmUlPRNF8BCoCRgIbEEoTkACDAA8w0eWK8rNGMAAAAASUVORK5CYII=');
  //        position: 100%;
  //        repeat: no-repeat;
  //        size: 2.2rem .4rem;
  //      }
  //      border: 1px solid $input-border;
  //      border-radius: .2rem;
  //      color: $input-text;
  //      cursor: pointer;
  //      font: {
  //        size: 1.3rem;
  //        weight: $font-weight-bold;
  //      }
  //      height: auto;
  //      outline: 0;
  //      padding: 1.3rem 1.5rem 1.4rem;
  //      transition: border-color .2s;
  //      width: 100%;
  //      @include responsive-tablet-max() {
  //        margin-left: 2rem;
  //      }
  //      @include responsive-desktop() {
  //        width: 11rem;
  //      }
  //    }
  //  }
  //  .bottom-copyright {
  //  }
  //
  //}
}

//        .footer--social-list {
//            @extend %clearfixtable;
//            font-size: 1.8rem;
//            list-style: none;
//            @include tablet-max {
//                float: right;
//                margin: {
//                    bottom: $gutter / 2;
//                    right: $gutter / 2;
//                }
//            }
//            @include desktop {
//                position: absolute;
//                right: 0;
//                top: 2rem;
//            }
//            .footer--social-list--item {
//                float: left;
//                &:not(:first-child) {
//                    margin-left: $hairline * 6;
//                }
//                .social-link {
//                    border: $hairline solid $layout-border;
//                    border-radius: 50%;
//                    color: $footer-social-color;
//                    display: block;
//                    height: 4rem;
//                    line-height: 4.5rem;
//                    text-align: center;
//                    transition-duration: .4s;
//                    transition-property: color, border, background-color;
//                    width: 4rem;
//                    > i {
//                        height: 1.6rem;
//                        position: relative;
//                        top: 25%;
//                        width: 1.6rem;
//                    }
//                    &.social-link--facebook {
//                        &:hover {
//                            background-color: $facebook;
//                            color: $color-white;
//                        }
//                    }
//                    &.social-link--twitter {
//                        &:hover {
//                            background-color: $twitter;
//                            color: $color-white;
//                        }
//                    }
//                    &.social-link--pinterest {
//                        &:hover {
//                            background-color: $pinterest;
//                            color: $color-white;
//                        }
//
//                    }
//                }
//            }
//
//        }
