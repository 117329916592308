@include block(table) {
  border-collapse: collapse;
  width: 100%;
  th {
    background: transparent;
    border: 0;
    font: {
      size: px-to-rem(12);
      weight: $font-weight-normal;
    }
    line-height: 1.5;
    padding: px-to-rem(12) px-to-rem(20) px-to-rem(12) 0;
    text-align: left;
    text-transform: uppercase;
    vertical-align: middle;
    width: px-to-rem(320);
  }
  td {
    font-size: px-to-rem(15);
    padding-left: 0;
    text-align: right;
    vertical-align: middle;
    width: calc(100% - #{px-to-rem(320)});
  }
  @include mod(totals) {
    th {
      color: $alt-text-color;
      text-transform: uppercase;
    }
    td {
    }
    strong {
      color: inherit;
    }
    tr:nth-last-child(2) th {
      padding-bottom: px-to-rem(24);
    }
    tr:nth-last-child(2) td {
      padding-bottom: px-to-rem(16);
    }
  }
  & + #{&} {
    margin-top: px-to-rem(24);
  }
  @include elem(total) {
    th, td {
      border-top: 1px solid $white;
      color: $main-text-color;
      font: {
        size: px-to-rem(15);
        weight: $font-weight-bold;
      }
      padding-top: px-to-rem(20);
    }
    td {
      padding-top: px-to-rem(8);
    }
  }
  @include elem(divider) {
    th, td {
      border-top: 1px solid $white;
      padding-top: px-to-rem(20);
    }
    td {
      padding-top: px-to-rem(8);
    }
  }
}
