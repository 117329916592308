@mixin block($name) {
  .#{$name} {
    @content;
  }
}

@mixin elem($name) {
  @at-root {
    &#{$element-separator}#{$name} {
      @content;
    }
  }
}

@mixin mod($name) {
  @at-root {
    &#{$modifier-separator}#{$name} {
      @content;
    }
  }
}

@mixin state($state, $prefix: $state-prefix) {
  @if $combined-state-selectors == true {
    @at-root {
      &.#{$prefix}-#{$state} {
        @content;
      }
    }
  } @else {
    @at-root {
      &#{$modifier-separator}#{$prefix}-#{$state} {
        @content;
      }
    }
  }
}
