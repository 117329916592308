a, abbr, acronym, address, article, aside, audio, blockquote, body, button, canvas, cite, code, dd, div, dl, dt, em, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, iframe, img, input, label, li, mark, nav, ol, p, pre, q, section, select, span, strong, sub, sup, table, tbody, td, textarea, tfoot, th, thead, time, tr, ul, video {
  margin: 0;
  padding: 0;
}

ul, ol {
  list-style: none;
}

nav ul {
  list-style: none;
}

button,
.btn,
.button,
a {
  outline: none;
  text-decoration: none;
}

button, html [type='button'], [type='reset'], [type='submit'], textarea {
  -webkit-appearance: none;
  background: none;
  border: 0;
  box-sizing: content-box;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: inherit;
  overflow: visible;
  vertical-align: inherit;
}

input:not([type=radio]):not([type=checkbox]) {
  -webkit-appearance: none;
}

button[disabled],
fieldset[disabled] button,
fieldset[disabled] input,
fieldset[disabled] optgroup,
fieldset[disabled] option,
fieldset[disabled] select,
fieldset[disabled] textarea,
input[disabled],
optgroup[disabled],
option[disabled],
select[disabled],
textarea[disabled] {
  cursor: not-allowed;
  opacity: .5
}
