@mixin flex-width($column, $breakpoint: '') {
  $val: percentage($column / $grid-columns);
  @if ($breakpoint) {
    @include media-breakpoint-up(#{$breakpoint}) {
      flex: 0 0 $val;
      max-width: $val;
    }
  } @else {
    flex: 0 0 $val;
    max-width: $val;
  }
}

@mixin flex-with-margin($column-width, $margin-width, $direction, $breakpoint: '') {
  $val: percentage($column-width / $grid-columns);
  $margin: percentage($margin-width / $grid-columns);
  @if ($breakpoint) {
    @include media-breakpoint-up(#{$breakpoint}) {
      flex: 0 0 ($val - $margin);
      margin-#{$direction}: $margin;
      max-width: ($val - $margin);
    }
  } @else {
    flex: 0 0 ($val - $margin);
    margin-#{$direction}: $margin;
    max-width: ($val - $margin);
  }
}

@mixin flex-with-pseudo-margin($column-width, $margin-width, $breakpoint: '') {
  $val: percentage($column-width / $grid-columns);
  $margin: percentage($margin-width / $grid-columns);
  @if ($breakpoint) {
    @include media-breakpoint-up(#{$breakpoint}) {
      flex: 0 0 ($val - $margin);
      max-width: ($val - $margin);
    }
  } @else {
    flex: 0 0 ($val - $margin);
    max-width: ($val - $margin);
  }
}

@mixin flex-order($column, $breakpoint: '') {
  @if ($breakpoint) {
    @include media-breakpoint-up(#{$breakpoint}) {
      order: $column;
    }
  } @else {
    order: $column;
  }
}

@mixin flex-margin($column, $direction, $breakpoint: '') {
  $val: percentage($column / $grid-columns);
  @if ($breakpoint) {
    @include media-breakpoint-up(#{$breakpoint}) {
      margin-#{$direction}: $val;
    }
  } @else {
    margin-#{$direction}: $val;
  }
}

@mixin flex-negative-margin($column, $direction, $breakpoint: '') {
  $val: percentage($column / $grid-columns);
  @if ($breakpoint) {
    @include media-breakpoint-up(#{$breakpoint}) {
      margin-#{$direction}: -$val;
    }
  } @else {
    margin-#{$direction}: -$val;
  }
}

@mixin breakpoint-auto($breakpoint) {
  @include media-breakpoint-up(#{$breakpoint}) {
    flex: 0 0 auto;
    max-width: none;
    width: auto;
  }
}

@mixin breakpoint-ml-auto($breakpoint) {
  @include media-breakpoint-up(#{$breakpoint}) {
    margin-left: auto !important;
  }
}

@mixin breakpoint-mr-auto($breakpoint) {
  @include media-breakpoint-up(#{$breakpoint}) {
    margin-right: auto !important;
  }
}

@mixin breakpoint-mx-auto($breakpoint) {
  @include media-breakpoint-up(#{$breakpoint}) {
    margin: {
      left: auto !important;
      right: auto !important;
    }
  }
}

@mixin d-none-up($breakpoint) {
  @include media-breakpoint-up(#{$breakpoint}) {
    display: none !important;
  }
}

@mixin d-block-up($breakpoint) {
  @include media-breakpoint-up(#{$breakpoint}) {
    display: block !important;
  }
}

@mixin d-inline-up($breakpoint) {
  @include media-breakpoint-up(#{$breakpoint}) {
    display: inline-block !important;
  }
}

@mixin d-none-down($breakpoint) {
  @include media-breakpoint-down(#{$breakpoint}) {
    display: none !important;
  }
}

@mixin d-block-down($breakpoint) {
  @include media-breakpoint-down(#{$breakpoint}) {
    display: block !important;
  }
}

@mixin d-inline-down($breakpoint) {
  @include media-breakpoint-down(#{$breakpoint}) {
    display: inline-block !important;
  }
}

@mixin top-align-items() {
  align-items: flex-start !important;
}

@mixin middle-align-items() {
  align-items: center !important;
}

@mixin bottom-align-items() {
  align-items: flex-end !important;
}

@mixin left-align-items() {
  justify-content: flex-start !important;
}

@mixin center-align-items() {
  justify-content: center !important;
}

@mixin right-align-items() {
  justify-content: flex-end !important;
}

@mixin around-align-items() {
  justify-content: space-around !important;
}

@mixin between-align-items() {
  justify-content: space-between !important;
}

@mixin d-none() {
  display: none !important;
}

@mixin mr-auto() {
  margin-right: auto !important;
}

@mixin ml-auto() {
  margin-left: auto !important;
}

@mixin mx-auto() {
  @include mr-auto();
  @include ml-auto();
}
