@include block(grid) {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  width: 100%;
  @include mod(reverse) {
    flex-direction: column-reverse;
  }
  @include elem(row) {
    flex-direction: row;
  }
}

@include block(column) {
  flex: 0 0 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include mod(cell) {
    padding-right: px-to-rem(12);
    & + & {
      padding: {
        left: px-to-rem(12);
        right: 0;
      }
    }
  }
}

@include block(mosaic-grid) {
  @include media-breakpoint-up(phone) {
    display: grid;
    grid-gap: px-to-rem(20);
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-breakpoint-up(desktop) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include elem(item) {
    background-color: $dark-blue-grey;
    color: $white;
    display: block;
    float: none;
    overflow: hidden;
    position: relative;
    transition: transform .2s ease-in-out, box-shadow .2s ease-in-out;
    width: auto;
    z-index: 1;
    @include media-breakpoint-down(phone) {
      margin-bottom: px-to-rem(20);
    }
    @include media-breakpoint-down(tablet) {
      &:nth-child(7n+1) {
        grid-column: auto/span 2;
      }
      &:nth-child(7n+3),
      &:nth-child(7n+5) {
        grid-row: auto/span 2;
      }
    }
    @include media-breakpoint-up(desktop) {
      &:nth-child(8n+1),
      &:nth-child(8n+6) {
        grid-column: auto/span 2;
        @include block(mosaic-grid) {
          @include elem(item) {
            @include elem(inner) {
              padding-bottom: 50%;
            }
          }
        }
      }
      &:nth-child(8n+2),
      &:nth-child(8n+5) {
        grid-row: auto/span 2;
      }
    }
    @include media-breakpoint-between(phone, desktop) {
      &:nth-child(7n+1) {
        @include block(mosaic-grid) {
          @include elem(item) {
            @include elem(inner) {
              padding-bottom: 50%;
            }
          }
        }
      }
    }
    &:before {
      content: attr(data-category);
      font: {
        size: px-to-rem(12);
      }
      left: px-to-rem(30);
      letter-spacing: 1.5px;
      line-height: 1;
      pointer-events: none;
      position: absolute;
      text-shadow: $text-shadow;
      text-transform: uppercase;
      top: px-to-rem(30);
      z-index: 5;
    }
    @include elem(inner) {
      background-position: 50%;
      background-size: cover;
      display: block;
      height: 100%;
      padding-bottom: 100%;
      position: relative;
      z-index: 1;
      @include elem(content) {
        background: linear-gradient(180deg, transparent, rgba($black, .4));
        bottom: 0;
        left: 0;
        padding: px-to-rem(30);
        pointer-events: none;
        position: absolute;
        right: 0;
        user-select: none;
      }
    }
    @include elem(title) {
      font: {
        size: px-to-rem(30);
        weight: $font-weight-bold;
      }
      letter-spacing: 0;
      line-height: 1.1;
      margin: 0 0 px-to-rem(24);
    }
    @include elem(button) {
      background-color: transparent;
      border-radius: 0;
      border: 1px solid $white;
      font: {
        size: px-to-rem(12);
      }
      padding: px-to-rem(10) px-to-rem(10) px-to-rem(8);
      text-transform: uppercase;
      transition: all .5s;
    }
  }
}

@each $breakpoint, $size in $media-breakpoints {
  .column--cell_at_#{$breakpoint} {
    @include media-breakpoint-up(#{$breakpoint}) {
      padding-right: px-to-rem(12);
      & + & {
        padding: {
          left: px-to-rem(12);
          right: 0;
        }
      }
    }
  }
}

@each $breakpoint, $size in $media-breakpoints {
  @for $i from 1 through 12 {
    .column__#{$i}_at_#{$breakpoint} {
      @include flex-width($i, $breakpoint);
    }
    .column__#{$i} {
      @include flex-width($i);
    }
  }
  .grid__row_at_#{$breakpoint} {
    @include media-breakpoint-up(#{$breakpoint}) {
      flex-direction: row;
    }
  }
  .grid__row_reverse_at_#{$breakpoint} {
    @include media-breakpoint-up(#{$breakpoint}) {
      flex-direction: row-reverse;
    }
  }
  .grid__column_reverse_at_#{$breakpoint} {
    @include media-breakpoint-up(#{$breakpoint}) {
      flex-direction: column-reverse;
    }
  }
  .grid__row_reverse_to_#{$breakpoint} {
    @include media-breakpoint-down(#{$breakpoint}) {
      flex-direction: row-reverse;
    }
  }
  .grid__column_reverse_to_#{$breakpoint} {
    @include media-breakpoint-down(#{$breakpoint}) {
      flex-direction: column-reverse;
    }
  }

  .grid__stretch_at_#{$breakpoint} {
    @include media-breakpoint-up(#{$breakpoint}) {
      align-items: stretch;
      flex-wrap: wrap;
      display: flex;
      > .column {
        min-height: 100vh;
      }
    }
  }
}
