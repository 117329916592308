@include block(social-share) {
  @include elem(text) {
    color: $alt-text-color;
    font: {
      family: $font-family-main;
      size: px-to-rem(10);
      weight: $font-weight-bold;
    }
    margin-bottom: px-to-rem(6);
    text-transform: uppercase;
  }
  @include elem(items) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    > div {
      border: 1px solid $border-color;
      border-radius: 50%;
      cursor: pointer;
      //flex: 1 1 auto;
      font-size: px-to-rem(20);
      margin: 0 px-to-rem(4);
      padding: px-to-rem(10);
      transition: all .3s ease-in-out;
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
      &:hover {
        background-color: $border-color;
      }
    }
  }
}
