@include block(promotion-block) {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(tablet) {
    flex-direction: row;
  }
  @include elem(item) {
    align-items: center;
    display: flex;
    @include clearfix-only-after();
    @include flex-with-margin(6, .25, right, tablet);
    & + & {
      @include flex-with-margin(6, .25, left, tablet);
      @include media-breakpoint-down(phone) {
        margin-top: px-to-rem(64);
      }
    }
    @include elem(media) {
      @include flex-width(6);
      float: left;
      @include media-breakpoint-up(tablet) {
        @include flex-with-margin(6, .5, left, tablet);
        float: right;
      }
    }
    @include elem(content) {
      @include flex-width(6);
      float: right;
      letter-spacing: -0.25px;
      @include media-breakpoint-up(tablet) {
        float: left;
      }
    }
    @include elem(heading) {
      color: $main-text-color;
      font: {
        size: px-to-rem(map_get($heading-sizes, h3));
        weight: $font-weight-light;
      }
      letter-spacing: -.3px;
      line-height: 1.2;
      margin: 0;
    }
    @include elem(description) {
      @include smallParagraph();
      margin-top: px-to-rem(12);
      @include media-breakpoint-up(tablet) {
        margin-top: px-to-rem(16);
      }
    }
    @include block(button) {
      margin-top: 20px;
      @include media-breakpoint-up(tablet) {
        margin-top: 32px;
      }
    }
  }
}
