html {
  @include antialias-font();
  @include optimize-read();
  background-color: $white;
  box-sizing: border-box;
  color: $main-text-color;
  font: {
    size: percentage(strip-unit($font-size-base/16));
  }
  letter-spacing: normal;
  line-height: 1.5;
  min-height: 100%;
  outline: none;
  text-size-adjust: 100%;
}

body {
  font: {
    family: $font-family-main;
  }
}

@include block(svg-icon) {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  vertical-align: middle;
  width: 1em;
}

a {
  color: $brand-color;
  text-decoration: none;
}

//p {
//  @include paragraph();
//
//  > a {
//    border-bottom: 1px dashed $brand-color;
//    color: $brand-color;
//  }
//}

@include block(styled) {
  font-family: $font-family-secondary;
}

hr {
  box-sizing: content-box;
  border: 0;
  border-bottom: 1px solid $border-color;
  height: 0;
  overflow: visible;
}

img {
  -ms-interpolation-mode: bicubic;
  max-width: 100%;
}

*,
:before,
:after {
  box-sizing: inherit;
}

@include block(wrapper) {
  min-height: 100vh;
}

@include block(row) {
  display: flex;
  flex-flow: row wrap;
  height: 100%;
}

@include block(main) {
  padding-bottom: px-to-rem(64);
  @include mod(single_page) {
    padding: 0;
    height: 100vh;
    min-height: 100vh;
  }
}

form {
  width: 100%;
}

@include block(mediaquery) {
  display: none;
  @each $item, $breakpoint in $media-breakpoints {
    @include media-breakpoint-up(#{$item}) {
      content: '#{$item}';
    }
  }
}

ul.styled {
  font-family: $font-family-main;
  list-style-type: none !important;
  margin: px-to-rem(24) 0;
  padding: 0 0 0 px-to-rem(20);

  li {
    margin-bottom: px-to-rem(12);
    position: relative;

    &::before {
      background-color: $red;
      content: '';
      height: px-to-rem(4);
      left: -#{px-to-rem(20)};
      position: absolute;
      top: px-to-rem(8);
      width: px-to-rem(4);
    }
  }
}

ul.styled.big {
  @extend .styled;

  li {
    padding-bottom: px-to-rem(16);
    &::before {
      top: px-to-rem(16);
    }
  }
}

.text-strike {
  text-decoration: line-through;
}

$palette: (
        'blue': $blue,
        'red': $red,
        'white': $white,
        'black': $black,
        'green': $green,
        'yella': $yella,
        'purple': $purple
);

@each $stain in $palette {
  $name: nth($stain, 1);
  $color: nth($stain, 2);
  .text--#{$name} {
    color: #{$color} !important;
  }
}

$is: (
        'is-1': $font-size-base*3,
        'is-2': $font-size-base*2.5,
        'is-3': $font-size-base*2,
        'is-4': $font-size-base*1.5,
        'is-5': $font-size-base*1.25,
        'is-6': $font-size-base,
);
@each $textsize in $is {
  $name: nth($textsize, 1);
  $size: nth($textsize, 2);
  .title.#{$name} {
    font: {
      family: $font-family-main;
      size: #{$size} !important;
      weight: $font-weight-semibold;
    }
  }
  .subtitle.#{$name} {
    font: {
      family: $font-family-main;
      size: #{$size} !important;
      weight: $font-weight-normal;
    }
  }

}
