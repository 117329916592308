@include block(intro-narrative) {
  @include media-breakpoint-up(tablet) {
    @include auto-center();
    max-width: px-to-rem(850);
  }
  @include elem(logo) {
    display: block;
    height: px-to-rem(72);
    margin: 0 auto px-to-rem(52);
    width: px-to-rem(240);
  }
  @include elem(headline) {
    @extend %headline;
  }
  @include elem(featured) {
    @include feature();
    text-align: center;
    p {
      @include feature();
    }
  }
  @include elem(separator) {
    margin-top: px-to-rem(56);
    width: px-to-rem(110);
  }
  @include block(readmore__action) {
    margin-top: px-to-rem(32);
    text-align: center;
  }
  @include elem(expanded) {
    display: none;
    margin-top: px-to-rem(48);
  }
  @include elem(title) {
    @include heading(h1);
    margin-bottom: px-to-rem(24);
  }
  @include elem(text) {
    + p,
    + h2 {
      margin-top: px-to-rem(25);
    }
    > p {
      font-size: px-to-rem(22);
      line-height: 1.6;
    }
  }
}
