@include block(masterhead) {
  color: $white;
  left: 0;
  margin-top: -#{px-to-rem(50)};
  overflow: hidden;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 6;
}

@include block(mastheadslider) {
  @include section();
  background-color: $image-background;
  height: 100%;
  @include media-breakpoint-down(desktop) {
    margin-top: -#{$header-slim-nav-height} !important;
    height: 100vh !important;
  }
  @include media-breakpoint-up(tablet) {
    margin-top: -$header-nav-height !important;
    height: 100vh !important;
  }
  @include block(slick-track) {
    height: 100vh;
    @include block(slick-slide) {
      height: 100vh !important;
      z-index: 1;
      @include media-breakpoint-up(desktop) {
        display: block;
        height: 100vh;
        left: 0;
        outline: none;
        position: relative;
        transition: opacity .5s linear;
        &:first-of-type {
          .homepage-slide {
            opacity: 0;
            animation: 1s .8s forwards fadeIn, 1s .8s forwards raise;
            .claim {
              .feature-title {
                animation: 1s 1.3s forwards fadeIn;
                opacity: 0;
              }
              h1 {
                animation: 1s 1.3s forwards fadeIn, 1s 1.3s forwards raiseIn;
                opacity: 0;
              }
              .callout-link {
                animation: 1s 1.5s forwards fadeIn, 1s 1.5s forwards raiseIn;
                opacity: 0;
              }
            }
          }
        }
      }
      > div {
        @include clearfix();
      }
      &.slick-active {
        position: relative !important;
        z-index: 10 !important;
      }
    }
  }
  @include block(homepage-slide) {
    height: 100%;
    width: 100%;
    @include elem(image-container) {
      @include fullscreen();
      background: {
        color: $image-background;
        position: 50%;
        repeat: no-repeat;
        size: cover;
      }
      overflow: hidden;
      @include media-breakpoint-up(desktop) {
        animation: 16s forwards zoomScale;
        transform: translateZ(0);
      }
    }
    @include elem(container) {
      @include container();
      height: 100%;
      position: relative;
      z-index: 3;
    }
    @include elem(claim) {
      bottom: px-to-rem(99);
      position: absolute;
      width: 80%;
      z-index: 5;
      @include media-breakpoint-up(phone) {
        width: 65% !important;
      }
      @include media-breakpoint-up(desktop) {
        bottom: px-to-rem(224) !important;
      }
      a {
        color: $white;
      }
    }
    @include elem(eyebrow) {
      @include eyebrow();
      color: $white;
      padding-bottom: px-to-rem(8);
    }
    @include elem(heading) {
      @include heading-entry(h1);
      line-height: px-to-rem(70);
      @include media-breakpoint-down(tablet) {
        font-size: px-to-rem(32);
        line-height: px-to-rem(40);
      }
    }
    @include block(callout-link) {
      margin-top: px-to-rem(42);
      @include block(separator) {
        margin-bottom: px-to-rem(36);
      }
    }
  }
  @include block(slick-tab-navigation) {
    bottom: 0;
    position: absolute;
    width: 100%;
    @include media-breakpoint-down(tablet) {
      display: none !important;
    }
    @include media-breakpoint-up(tablet) {
      animation: 1s 1.5s forwards fadeIn;
      opacity: 0;
    }
    @include block(container) {
      @include container();
      display: flex;
      position: relative;
    }
    @include block(sticky-icon-container) {
      border-top: 1px solid $alpha-white-border-color;
      flex: 1;
      position: relative;
      text-align: right;
      @include block(item) {
        align-items: flex-end;
        border-left: 1px solid $alpha-white-border-color;
        color: $white;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 65%;
        left: 0;
        position: absolute;
        right: 0;
        top: px-to-rem(20);
        transition: transform .5s ease;}
    }
    @include block(sticky-tab-container) {
      flex: 20;
      nav {
        border-top: 1px solid $alpha-white-border-color;
        display: flex;
        @include block(nav-item) {
          display: flex;
          flex: 1;
          padding: {
            bottom: px-to-rem(40);
            left: px-to-rem(8);
            top: px-to-rem(40);
          }
          position: relative;
          @include block(nav-zone) {
            @include fullscreen();
            background-color: transparent;
            cursor: pointer;
            z-index: 100;
          }
          @include block(nav-text) {
            align-items: center;
            display: flex;
            font-size: px-to-rem(12);
            @include block(number-marker) {
              @include numberMarker(diamond);
              width: px-to-rem(24) !important;
              color: $white;
            }
            span {
              color: $white;
              font-weight: $font-weight-bold;
              line-height: 1.5;
              padding-left: px-to-rem(14);
              width: 85%;
            }
          }
        }
      }
      @include block(slick-tab-activebar) {
        background-color: $white;
        height: px-to-rem(4);
        opacity: .75;
        position: absolute;
        top: 0;
        transition: width .35s ease, left .35s ease;
      }
    }
  }
  @include block(slick-dots) {
    bottom: px-to-rem(56);
    height: px-to-rem(34);
    margin: 0 auto;
    position: relative;
    text-align: center;
    width: 49%;
    @include media-breakpoint-up(desktop) {
      display: none;
    }
    li {
      cursor: pointer;
      display: inline-block;
      height: px-to-rem(20);
      margin: 0 px-to-rem(5);
      padding: 0;
      position: relative;
      width: px-to-rem(12);
      button {
        background: transparent;
        border: 0;
        color: transparent;
        cursor: pointer;
        display: block;
        font-size: 0;
        height: px-to-rem(20);
        line-height: 0;
        outline: none;
        padding: px-to-rem(5);
        width: px-to-rem(20);
        &:before {
          color: $alpha-white-border-color;
          content: "•";
          font-size: px-to-rem(32);
          height: px-to-rem(20);
          left: 0;
          line-height: px-to-rem(20);
          opacity: 1;
          position: absolute;
          text-align: center;
          top: 0;
          width: px-to-rem(20);
        }
      }
    }
    @include block(slick-active) {
      button {
        &:before {
          color: $white;
        }
      }
    }
  }
}
