@include block(point-of-interest) {
  margin-top: px-to-rem(40);
  @include elem(name) {
  }
  @include elem(type) {
  }
  @include elem(topchoice) {
    color: $red;
  }
  @include elem(divider) {
    margin-top: px-to-rem(32);
    @include media-breakpoint-up(desktop) {
      margin-top: px-to-rem(64);
    }
  }
  @include elem(image) {
    width: 100%;
    > figure {
      @include cover();
      overflow: hidden;
      max-width: 100%;
      min-height: px-to-rem(450);
      height: auto;
      width: 100%;
    }
    + .point-of-interest__description {
      margin-top: px-to-rem(48);
      padding: 0 px-to-rem(16);
      @include media-breakpoint-up(desktop) {
        padding: 0 px-to-rem(32);
      }
    }
  }
  @include elem(content) {
    @include media-breakpoint-up(desktop) {
      padding-right: px-to-rem(32);
    }
  }
  @include elem(amenities) {
    font-family: $font-family-main;
  }
  @include elem(aside) {
    @include media-breakpoint-down(tablet) {
      margin-top: px-to-rem(32);
      padding: 0 px-to-rem(16);
      width: 100%;
    }
  }
  @include elem(description) {
    font: {
      family: $font-family-secondary;
      size: px-to-rem(20);
    }
    line-height: 1.8;
    margin-bottom: px-to-rem(32);
    @include media-breakpoint-up(desktop) {
      margin-bottom: px-to-rem(64);
      max-width: $minicontent-max-width;
    }
    p {
      margin-bottom: px-to-rem(24);
    }
  }
  @include elem(additional) {
    padding-bottom: px-to-rem(16);
    @include elem(title) {

    }
    @include elem(list) {

    }
    @include elem(item) {
      display: flex;
      font-size: px-to-rem(14);
      line-height: 1.4;
      margin: px-to-rem(16) 0;
      @include elem(label) {
        display: inline-block;
        min-width: px-to-rem(80);
      }

    }
  }
  @include block(ad) {
    margin: px-to-rem(48) 0 !important;
  }
}
