@include block(cart) {
  margin-top: px-to-rem(32);
  @include elem(list) {
    @include media-breakpoint-up(desktop) {
      flex-grow: 1;
      margin-right: px-to-rem(32);
    }
    @include elem(headings) {
      @include media-breakpoint-down(tablet) {
        display: none !important;
      }
    }
    @include elem(item) {
      align-items: center;
      border-bottom: 1px solid $border-color;
      display: flex;
      justify-content: space-between;
      padding: px-to-rem(14) 0;
      @include media-breakpoint-down(tablet) {
        flex-wrap: wrap;
      }
      @include elem(column) {
        width: 20%;
        &:first-of-type {
          width: 60%;
        }
        &:last-of-type {
          padding-right: px-to-rem(14);
          text-align: center;
        }
        @include media-breakpoint-down(tablet) {
          text-align: right;
          width: 20%;
          &:first-of-type {
            text-align: left;
            width: 80%;
          }
          &:last-of-type {
            clear: both;
            padding: 0;
            text-align: right;
            width: 100%;
          }
        }

      }
    }
  }
  @include elem(item) {
    @include elem(summary) {

    }
    @include elem(media) {
      display: inline-block;
      max-width: px-to-rem(48);
      vertical-align: top;
    }
    @include elem(link) {
      border-bottom: none;
      color: $main-text-color !important;
    }
    @include elem(info) {
      display: inline-flex;
      flex-direction: column;
      max-width: calc(100% - #{px-to-rem(50)});
      > p {
        line-height: 1.5 !important;
      }
    }
    @include elem(title) {
      font-size: px-to-rem(17);
      @include media-breakpoint-down(tablet) {
        font-size: px-to-rem(15);
      }
    }
    @include elem(ptype) {
      color: $light-text-color;
      font: {
        size: px-to-rem(11) !important;
        weight: $font-weight-bold;
      }
      text-transform: uppercase;
      @include media-breakpoint-down(tablet) {
        font-size: px-to-rem(10);
      }
    }
    @include elem(price) {

    }
    @include elem(qta) {
      @include block(property) {
        margin-bottom: 0;
      }
    }
  }
  @include elem(summary) {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-down(tablet) {
      margin-top: px-to-rem(32);
    }
    @include elem(container) {
      background-color: $light-blue;
      padding: px-to-rem(20);
    }
    @include elem(heading) {
      border-bottom: 1px solid $white;
      padding: 0 0 px-to-rem(12);
      font: {
        size: px-to-rem(12);
        weight: $font-weight-bold;
      }
      text-align: center;
    }
    @include elem(table) {
      margin-top: px-to-rem(20);
    }
    @include block(button) {
      margin: px-to-rem(32) 0;
    }
  }
}

@include block(cart-summary) {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  @include elem(addedtocart) {
    align-items: center;
    background-color: $alt-light-background;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: -#{px-to-rem(8)};
    min-height: px-to-rem(156);
    padding: px-to-rem(24) px-to-rem(8);
    position: relative;
    @include elem(media) {
      min-height: px-to-rem(70);
      padding: 0 px-to-rem(8) 0 0;
      position: relative;
      vertical-align: top;
      @include mod(link) {
        padding-top: 120%;
      }
      @include mod(image) {
        @include cover-shadow();
      }
    }
    @include elem(text) {
      border-bottom: 0;
      color: $main-text-color;
      cursor: pointer;
      font: {
        size: px-to-rem(18);
      }
      line-height: 1.2;
      margin-top: px-to-rem(16);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    @include elem(icon) {
      align-items: center;
      border-radius: 50%;
      color: $green;
      display: inline-flex;
      fill: $green;
      flex: 0 0 auto;
      justify-content: center;
      line-height: normal;
      height: px-to-rem(40);
      margin-right: px-to-rem(12);
      position: relative;
      text-align: center;
      vertical-align: middle;
      min-width: px-to-rem(40);
      width: px-to-rem(40);
    }
    @include block(badge) {
      border: 2px solid $white;
      border-radius: 50%;
      height: px-to-rem(28);
      left: #{px-to-rem(45)};
      line-height: px-to-rem(28);
      padding: 0;
      top: -#{px-to-rem(13)};
      width: px-to-rem(28);
      @include block(icon) {
        position: relative;
        top: 6px;
      }
    }
    @include elem(buttons) {
      margin-top: px-to-rem(16);
      > a {
        margin: 0 px-to-rem(8);
      }
    }
  }
  @include elem(content) {
    display: block;
    margin: px-to-rem(10) 0 0;
    overflow-y: scroll;
    flex-grow: 1;
    @include mod(loading) {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }
  @include elem(title) {
    border-bottom: 1px solid $light-border-color;
    font: {
      size: px-to-rem(12);
      weight: $font-weight-bold;
    }
    margin: 0;
    padding: px-to-rem(32) 0;
    text: {
      align: center;
      transform: uppercase;
    }
  }
  @include elem(list) {
    margin: 0;
    padding: px-to-rem(16) 0 px-to-rem(32);

    @include elem(item) {
      align-items: flex-start;
      border-bottom: 1px solid $light-border-color;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: px-to-rem(16) 0;
      &:last-of-type {
        border-bottom: 0;
      }
      @include media-breakpoint-up(tablet) {
        padding: px-to-rem(24);
      }
      @include elem(summary) {
        flex: 1 0 auto;
        padding-right: px-to-rem(8);
        text-align: left;
        width: 70%;
      }
      @include elem(media) {
        display: inline-block;
        padding: 0 px-to-rem(8) 0 0;
        max-width: px-to-rem(45);
        vertical-align: top;
        @include mod(link) {

        }
        @include mod(image) {
          @include cover-shadow();
        }
      }
      @include elem(info) {
        display: inline-block;
        max-width: calc(100% - #{px-to-rem(50)});
      }
      @include elem(title) {
        margin: 0;
        @include mod(link) {
          border-bottom: 0;
          color: $main-text-color;
          cursor: pointer;
          font: {
            size: px-to-rem(16);
          }
          line-height: 1.2;
          transition: color .2s ease-in-out;
          &:hover {
            color: $link-color;
            text-decoration: none;
          }
        }
      }
      @include elem(subtitle) {
        border-bottom: 0;
        color: $main-text-color;
        cursor: pointer;
        font-size: px-to-rem(13);
        line-height: 1.4;
        margin: px-to-rem(4) 0 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      @include elem(type) {
        margin: px-to-rem(4) 0 0;
        color: $light-text-color;
        font: {
          size: px-to-rem(11);
          weight: $font-weight-bold;
        }
        text-transform: uppercase;
      }
      @include elem(extra) {
        margin: px-to-rem(4) 0 0;
        color: $alt-text-color;
        font: {
          size: px-to-rem(11);
        }
      }
      @include elem(totals) {
        flex: 1 0 auto;
        text-align: right;
        width: 30%;
      }
      @include elem(price) {
        display: flex;
        flex-wrap: wrap;
        font: {
          size: px-to-rem(14);
          weight: $font-weight-bold;
        }
        justify-content: flex-end;
        margin: 0;
      }
      @include elem(promo) {
        color: $alt-text-color;
        font: {
          size: px-to-rem(13);
          weight: $font-weight-semibold;
        }
        text-decoration: line-through;
      }
      @include elem(quantity) {
        font-size: px-to-rem(11);
      }
    }
  }
  @include elem(footer) {
    border-top: 2px solid $light-blue;
    box-shadow: 0 -#{px-to-rem(16)} px-to-rem(100) $white;
    flex-shrink: 0;
    margin: 0 -#{px-to-rem(8)} -#{px-to-rem(8)};
    position: relative;
    text-align: center;
  }
  @include elem(subtotal) {
    background: $white;
    display: block;
    flex-grow: 0;
    height: px-to-rem(60);
    line-height: px-to-rem(60);
    margin: 0 px-to-rem(8);
    text-transform: uppercase;
    @include elem(wrapper) {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 px-to-rem(8);
      @include media-breakpoint-up(tablet) {
        padding: 0 px-to-rem(16);
      }
    }
    @include elem(label) {
      color: $alt-text-color;
      flex: 1 0 auto;
      font-size: px-to-rem(12);
      line-height: 1.5;
      text: {
        align: left;
        transform: uppercase;
      }
      vertical-align: middle;
      width: 70%;
    }
    @include elem(value) {
      flex: 1 0 auto;
      font: {
        size: px-to-rem(16);
        weight: $font-weight-bold;
      }
      text-align: right;
      vertical-align: middle;
      width: 30%;

    }
  }
  @include elem(buttons) {
    margin: 0;
    text-align: center;
    @include block(button) {
      border-radius: 0;
      height: px-to-rem(72);
      line-height: px-to-rem(37);
    }
  }
}

@include block(shopping-bag) {
  color: $main-text-color;
  .icon {
    color: $main-text-color;
    fill: $main-text-color;
  }
}

#cart {
    .vc-container {
        border-radius: 0 !important;
    }
}
