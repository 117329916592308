@function breakpoint-next ($item, $breakpoints: $media-breakpoints, $breakpoint-names: map-keys($media-breakpoints)) {
  $n: index($breakpoint-names, $item);
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min ($item, $breakpoints: $media-breakpoints) {
  $min: map-get($breakpoints, $item);
  @return if($min != 0, $min, null);
}

@function breakpoint-max ($item, $breakpoints: $media-breakpoints) {
  $next: breakpoint-next($item, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - 1px, null);
}

@mixin media-breakpoint-up($item, $breakpoints: $media-breakpoints) {
  $min: breakpoint-min($item, $breakpoints);
  @if $min {
    @media all and (min-width: px-to-em($min)) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($item, $breakpoints: $media-breakpoints) {
  $max: breakpoint-max($item, $breakpoints);
  @if $max {
    @media all and (max-width: px-to-em($max)) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-between($lower, $upper, $breakpoints: $media-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);
  @media all and (min-width: px-to-em($min)) and (max-width: px-to-em($max)) {
    @content;
  }
}

@mixin media-breakpoint-only($item, $breakpoints: $media-breakpoints) {
  $min: breakpoint-min($item, $breakpoints);
  $max: breakpoint-max($item, $breakpoints);
  @if $min != null and $max != null {
    @media all and (min-width: px-to-em($min)) and (max-width: px-to-em($max)) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($item) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($item) {
      @content;
    }
  }
}
