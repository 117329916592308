// GREYS
$dark-blue-grey: rgb(44, 54, 67) !default; // #2c3643
$light-blue-grey: rgb(235, 240, 240) !default; // #ebf0f0
$line-grey: rgb(222, 225, 227) !default; // #dee1e3
$light-grey: rgb(142, 149, 159) !default; // #8e959f
$sublight-grey: rgb(197, 209, 215) !default; // #c5d1d7
$ultralight-grey: rgb(246, 246, 246) !default; // #f6f6f6
$light-blue: rgb(233, 242, 248) !default; // #e9f2f8

// NEW COLORS
$jodhpurblue: rgb(0, 87, 217) !default; // #0057d9
$blue: rgb(41, 124, 187) !default; // #297cbb
$softblue: rgb(138, 190, 229) !default; // #8abee5
$red: rgb(255, 77, 79) !default; // #ff4d4f
$green: rgb(22, 201, 141) !default; // #16c98d
$darkgreen: rgb(71, 168, 153) !default; // #47a899
$softgreen: rgb(199, 230, 170) !default; // #c7e6aa
$yella: rgb(254, 239, 109) !default; // #feef6d
$orange: rgb(255, 200, 63) !default; // #ffc83f
$beige: rgb(203, 203, 175) !default; // #cbcbaf
$purple: rgb(146, 84, 222) !default; // #9254de
$mauve: rgb(104, 78, 121) !default; // #684e79
$pink: rgb(255,112, 142) !default; //#ff708e
$white: rgb(255, 255, 255) !default; // #fff
$black: rgb(0, 0, 0) !default; // #000


// HEADER COLORS
$header-blue: rgb(29, 80, 141) !default;
$header-red: rgb(187, 33, 51) !default;

// ICON COLORS
$icon-grey: rgb(204, 204, 204) !default; // #ccc
$icon-darkgrey: rgb(142, 149, 159) !default;  // #8e959f;

$brand-color: $blue;
$main-text-color: $dark-blue-grey !default;
$alt-text-color: $light-grey !default;
$light-text-color: $sublight-grey !default;
$text-shadow: 1px 1px 0 rgba($black, .1);

$main-background: $white !default;
$alt-light-background: $ultralight-grey !default;
$alt-dark-background: $dark-blue-grey !default;
$image-background: $dark-blue-grey !default;

$border-color: rgba(226, 227, 229, 1) !default; // #e2e3e5
$light-border-color: $light-blue-grey !default;
$alpha-white-border-color: rgba($white, .3);
$input-border: $sublight-grey !default;

$divider: $light-blue-grey !default;
$dark-divider: $line-grey !default;
$button-line-color: $light-blue-grey !default;
$input-field-line-color: $line-grey !default;

$link-color: $brand-color !default;

// PAYPAL
