@include block('travel-alert') {
  background-color: $orange;
  font-size: px-to-rem(14);
  padding: px-to-rem(20);
  text-align: center;
  @include elem(content) {
    @include auto-center();
    max-width: $content-max-width;
    a {
      border-bottom: 0;
      color: #333;
      text-decoration: underline;
    }
    p {
      font-size: px-to-rem(14);
    }
  }
}
