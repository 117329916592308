@include block(breaker) {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: $content-max-width;
  padding: {
    bottom: px-to-rem(32);
    top: px-to-rem(32);
  }
  @include elem(item) {
    display: flex;
    flex: 1 0 25%;
    justify-content: center;
    max-width: px-to-rem(320);
    padding: 0 px-to-rem(8);
    @include media-breakpoint-down(desktop) {
      margin-bottom: px-to-rem(48);
      max-width: none;
      flex: 1 0 50%;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    @include elem(image) {
      display: flex;
      @include elem(picture) {
        @include cover();
        height: px-to-rem(200);
        max-width: 100%;
        width: 100%;
      }
    }
    @include elem(heading) {
      color: $main-text-color;
      font: {
        size: px-to-rem(16);
        weight: $font-weight-bold;
      }
      letter-spacing: -.2px;
      margin-top: px-to-rem(16);
    }
    @include elem(copy) {
      color: $alt-text-color;
      font: {
        size: px-to-rem(16);
      }
      line-height: 1.3;
      margin: px-to-rem(10) auto px-to-rem(24);
    }
    @include elem(link) {
      display: block;
      max-width: 100%;
      width: 100%;
      &:hover {
        svg {
          transform: translateY(-#{px-to-rem(5)});
        }

        h3 {
          color: $link-color;
        }
      }
    }
    @include elem(advertorial) {
      @include eyebrow();
      color: $light-text-color;
      padding-top: px-to-rem(8);
    }
  }
}
