@include block(product) {
  @include elem(grid) {
    @include flex-negative-margin(.25, left);
    @include flex-negative-margin(.25, right);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    @include elem(item) {
      @include flex-width(6);
      @include flex-width(4, phone);
      @include flex-width(2, desktop);
    }
  }
  @include elem(summary) {
    margin-bottom: px-to-rem(20);
    padding: px-to-rem(10);
    position: relative;
    @include elem(link) {
      display: block;
    }
    @include elem(media) {
      margin: 0 0 px-to-rem(14);
      //max-width: px-to-rem(150);
      transition: transform .4s ease-in-out;
      @include elem(wrapper) {
        display: block;
        height: 0;
        padding: 0 0 155%;
        position: relative;
      }
      @include elem(chapter) {
        background-color: white;
        bottom: -#{px-to-rem(6)};
        display: block;
        position: absolute;
        right: -#{px-to-rem(6)};
        > svg {
          height: px-to-rem(36);
          width: px-to-rem(36);
        }
      }
      @include elem(image) {
        bottom: 0;
        max-width: 100%;
        position: absolute;
        vertical-align: bottom;
      }
    }
    @include elem(info) {
      margin: 0;
      text-align: left;
      transition: transform .4s ease-in-out;
    }
    @include elem(name) {
      color: $main-text-color;
      hyphens: auto;
      font: {
        size: px-to-rem(14);
        weight: $font-weight-bold;
      }
      line-height: 1.3 !important;
      margin: 0 0 px-to-rem(4);
      max-height: px-to-rem(42);
      overflow: hidden;
      text-overflow: ellipsis;
      transition: color .4s ease-in-out;
      word-break: break-word;
    }
    @include elem(type) {
      color: $light-text-color;
      font: {
        size: px-to-rem(11);
        weight: $font-weight-bold;
      }
      text-transform: uppercase;
    }
    @include elem(price) {
      text-align: right;
      
      @include mod(list) {
        font-size: 80%;
        color: $red;
        text-decoration: line-through;
        margin-right: 0.3rem;
      }
      
      @include mod(selling) {
        font-size: 120%;
        color: $dark-blue-grey;
        font-weight: bolder;
      }
    }
    &:hover {
      @include block(product) {
        @include elem(summary) {
          @include elem(media) {
            transform: translateY(-#{px-to-rem(6)});
          }
          @include elem(info) {
            transform: translateY(-#{px-to-rem(4)});
          }
          @include elem(name) {
            color: $brand-color;
          }
        }
      }
    }
  }
}

@include block(product-details) {
  @include elem(media) {
    margin: 0 0 px-to-rem(16);
    text-align: center;
    @include media-breakpoint-up(desktop) {
      padding-right: px-to-rem(32);
    }
    img {
      @include cover-shadow();
      @include auto-center();
      margin-bottom: px-to-rem(20);
      max-width: 90%;
      @include media-breakpoint-up(tablet) {
        max-width: 25rem !important;
      }
    }
  }
  @include elem(name) {
    @include heading-display(h2);
    line-height: 1.2;
    margin-bottom: px-to-rem(14);
    span {
      color: $alt-text-color;
      display: block;
      font-size: px-to-rem(24);
    }
  }
  @include elem(edition) {
    color: $alt-text-color;
    font: {
      size: px-to-rem(12);
      weight: $font-weight-light;
    }
    margin: 0;
  }
  @include elem(variants) {
    margin: px-to-rem(32) 0;
    @include elem(item) {
      align-items: center;
      border-top: 1px solid $border-color;
      display: flex;
      justify-content: space-between;
      padding: px-to-rem(14) 0;
      min-height: 4.2rem;
      &:last-child {
        border-bottom: 1px solid $border-color;
      }
      @include mod(chapters-list) {
        border-bottom: 0 !important;
      }
      @include elem(column) {
        width: 30%;
        &:last-of-type {
          padding-left: px-to-rem(18);
          text-align: center;
          width: 40%;
        }
        @include media-breakpoint-up(tablet) {
          &:first-of-type {
            width: 40%;
          }
          &:last-of-type {
            width: 30%;
          }
        }
      }
      @include elem(name) {
        font: {
          size: px-to-rem(16);
          weight: $font-weight-bold;
        }
        line-height: 1.4;
        margin: 0;
        @include media-breakpoint-down(tablet) {
          font-size: px-to-rem(14);
          line-height: 1.1;
        }
      }
      @include elem(info) {
        color: $alt-text-color;
        font: {
          size: px-to-rem(12);
          weight: $font-weight-light;
        }
        line-height: 1.4;
        margin: 0;
      }
      @include elem(price) {
        font: {
          size: px-to-rem(16);
          weight: $font-weight-bold;
        }
        line-height: 1.4;
        margin: 0;
        @include media-breakpoint-down(tablet) {
          font-size: px-to-rem(14);
          line-height: 1.1;
          text-align: right;
        }
        @include mod(message) {
          color: $alt-text-color;
          font: {
            size: px-to-rem(12);
          }
        }
        @include mod(regular) {
        }
        @include mod(promo) {
          //color: $red;
        }
        @include mod(discounted) {
          color: $red;
          font: {
            weight: $font-weight-normal;
            size: px-to-rem(13);
          }
          text: {
            decoration: line-through;
          }
        }
      }
      
    }
    
  }
  @include elem(description) {
    margin-bottom: px-to-rem(48);
    p {
      font-size: px-to-rem(16) !important;
      line-height: px-to-rem(24) !important;
      margin-bottom: px-to-rem(24) !important;
    }
    ul {
      @extend .styled;
      
      li {
        margin-bottom: 0;
      }
    }
  }
  @include elem(attributes) {
    border-top: 1px solid $border-color;
    font-size: px-to-rem(16);
    margin: px-to-rem(32) 0;
    padding-top: px-to-rem(32);
  }
  @include elem(attribute) {
    margin-bottom: px-to-rem(10);
    @include mod(header) {
      @include heading-display(h5);
      margin-bottom: px-to-rem(10);
    }
  }
  @include elem(recommendations) {
    border-top: 1px solid $border-color;
    margin-top: 0 !important;
    padding-top: px-to-rem(80);
    @include media-breakpoint-up(tablet) {
      margin: px-to-rem(80) 0;
    }
  }
}

@include block(chapter-list) {
  background-color: $light-blue;
  padding: px-to-rem(24);
  @include elem(item) {
    & + & {
      padding-top: px-to-rem(12);
    }
    @include elem(cell) {
      display: inline-block;
      margin-top: px-to-rem(4);
      max-width: px-to-rem(14);
      vertical-align: top;
      & + & {
        margin: 0 0 0 px-to-rem(8);
        max-width: calc(100% - #{px-to-rem(26)});
      }
    }
    @include elem(name) {
      cursor: pointer;
      font-size: px-to-rem(16);
      margin: 0 px-to-rem(8) 0 0;
      span {
        color: $alt-text-color;
        font-size: px-to-rem(12);
      }
    }
  }
  @include elem(footer) {
    align-items: center;
    background-color: $light-blue;
    border-top: 1px solid $white;
    display: flex;
    justify-content: space-between;
    padding: px-to-rem(14);
    > div {
      padding: 0 !important;
    }
  }
}

.shop-newrelease {
  border: 1px solid $green;
  margin-bottom: px-to-rem(36);
  padding: px-to-rem(24) px-to-rem(32) px-to-rem(32);
  &__heading {
    color: $green !important;
    font-size: px-to-rem(11);
    font-weight: $font-weight-bold;
    margin-bottom: px-to-rem(16);
    text-transform: uppercase;
  }
  
  &__content {
    display: block;
  }
  
  &__media {
    display: inline-block;
    padding: 0 px-to-rem(8) 0 0;
    max-width: px-to-rem(52);
    vertical-align: top;
    
    img {
      @include cover-shadow();
    }
  }
  
  &__info {
    display: inline-block;
    max-width: calc(100% - #{px-to-rem(64)});
  }
  
  &__title {
    color: $main-text-color;
    cursor: pointer;
    font-size: px-to-rem(20);
    font-weight: $font-weight-normal;
    line-height: 1.2;
    margin-bottom: px-to-rem(8);
  }
  
  &__isbn {
    color: $main-text-color;
    cursor: pointer;
    font-size: px-to-rem(12);
    font-weight: $font-weight-normal;
    line-height: 1.3;
    margin-bottom: px-to-rem(2);
    span {
      font-weight: $font-weight-light;
    }
  
  }
  
  &__date {
    color: $main-text-color;
    cursor: pointer;
    font-size: px-to-rem(13);
    font-weight: $font-weight-normal;
    line-height: 1.3;
    span {
      font-weight: $font-weight-light;
    }
  }
  
}
