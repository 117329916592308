@include block(spotter) {
  margin-top: px-to-rem(64);
  @include elem(image) {
    display: block;
  }
  @include elem(text) {
    padding: px-to-rem(64) px-to-rem(32);
    @include media-breakpoint-down(desktop) {
      margin-top: 0;
      padding-bottom: px-to-rem(48);
    }
  }
  @include elem(title) {
    color: $main-text-color;
    font: {
      size: px-to-rem(40);
      weight: $font-weight-bold;
    }
    letter-spacing: -.2px;
    line-height: 1.1;
    margin-bottom: px-to-rem(14);
  }
  @include elem(blurb) {
    > p {
      color: $alt-text-color;
      font: {
        size: px-to-rem(18);
        weight: $font-weight-semibold;
      }
      line-height: 1.5;
    }
  }
  @include elem(button) {
    margin-top: px-to-rem(26);
  }
  @include block(grid) {
    align-items: stretch;
    justify-content: center;
    margin: 0 auto;
    max-width: $content-max-width;
  }
  @include block(column) {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
