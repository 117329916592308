@include block(hero) {
  height: 100vh !important;
  margin: 0 auto !important;
  position: relative !important;
  @include mod(article) {
    position: relative;
    max-width: $content-max-width;
    margin: 0 auto;
    max-height: px-to-rem(850);
    height: 80vw;
    min-height: px-to-rem(592);
  }
  @include elem(image) {
    @include fullscreen();
    background: {
      color: $image-background;
      position: 50% center;
      repeat: no-repeat;
      size: cover;
    }
  }
  @include elem(container) {
    @include container;
    height: 100%;
  }
  @include elem(row) {
    @extend .row;
  }
  @include elem(column) {
    @include flex-margin(1, left, tablet);
    @include flex-width(11, tablet);
  }
  @include elem(claim) {
    position: absolute;
    bottom: px-to-rem(56);
    width: 70%;
    z-index: 3;
    @include media-breakpoint-up(tablet) {
      bottom: px-to-rem(72);
      width: 65%;
    }
  }
  @include elem(featured-heading) {
    color: $white;
    display: inline-block;
    left: 0;
    min-width: px-to-rem(108);
    position: absolute;
    right: 0;
    text-align: center;
    top: px-to-rem(32);
    @include media-breakpoint-up(tablet) {
      top: px-to-rem(56);
    }
    h5 {
      text-transform: uppercase;
    }
    @include block(separator) {
      background-color: rgba($white, .3);
      height: 1px;
      margin: px-to-rem(16) auto 0;
      width: px-to-rem(142);
    }
  }
  @include elem(eyebrow) {
    color: $white;
    display: inline-block;
    font: {
      size: px-to-rem(11);
      weight: $font-weight-bold;
    }
    padding-bottom: px-to-rem(16);
    text-transform: uppercase;
  }
  @include elem(heading) {
    color: $white;
    font: {
      family: $font-family-main;
      size: 28px;
      weight: $font-weight-bold;
    }
    line-height: 1.2;
    letter-spacing: -#{px-to-rem(1)};
    padding-bottom: px-to-rem(32);
    @include media-breakpoint-up(tablet) {
      font-size: calc(#{px-to-rem(11)} + 3vw);
    }
    @include media-breakpoint-up(wide) {
      font-size: px-to-rem(56);
    }
    a {
      color: $white;
    }
  }
  @include elem(callout-link) {
    display: none;
    @include block(separator) {
      background-color: rgba($white, .3);
      display: block;
      height: 1px;
      margin-bottom: px-to-rem(8);
      width: px-to-rem(150);
      @include media-breakpoint-up(phone) {
        margin-bottom: px-to-rem(24);
      }
    }
    @include media-breakpoint-up(tablet) {
      display: block;
    }
  }
}
