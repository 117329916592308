@include block(globalnav) {
  position: relative;
  z-index: 100;
  @include media-breakpoint-down(phone) {
    border-bottom: 1px solid $border-color;
  }
  @include media-breakpoint-up(tablet) {
    //animation: 1s 1s forwards fadeIn;
    //opacity: 0;
  }
  @include elem(container) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    padding-left: px-to-rem(24);
    padding-right: px-to-rem(24);
    position: relative;
    width: 100%;
    @include media-breakpoint-up(tablet) {
      padding-left: px-to-rem(48);
      padding-right: px-to-rem(48);
    }
  }
  @include elem(content) {
    align-items: center;
    display: flex;
    height: px-to-rem($header-slim-nav-height);
    justify-content: center;
    position: relative;
    @include media-breakpoint-up(tablet) {
      height: px-to-rem($header-nav-height);
    }
  }
  @include elem(logo) {
    @include auto-center();
    color: $jodhpurblue;
    display: block;
    fill: $jodhpurblue;
    position: relative;
    width: px-to-rem(144);
    z-index: 1;
    @include media-breakpoint-up(tablet) {
      margin-left: 0
    }
    @include media-breakpoint-up(desktop) {
      width: px-to-rem(192);
    }
    @include media-breakpoint-up(wide) {
      margin-left: auto;
    }
  }
  @include elem(search) {
    display: none;
    @include media-breakpoint-up(wide) {
      color: rgba($dark-blue-grey, .38);
      cursor: pointer;
      display: block;
      font: {
        size: px-to-rem(24);
        weight: $font-weight-light;
      }
      left: 0;
      overflow: hidden;
      position: absolute;
      text-overflow: ellipsis;
      user-select: none;
      white-space: nowrap;
      width: auto;
    }
    @include media-breakpoint-up(hires) {
      text-align: center;
      width: auto;
    }
    svg {
      color: $main-text-color;
      font-size: px-to-rem(18);
      margin-right: px-to-rem(14);
      transition: margin .4s;
      vertical-align: 0;
    }
    @include elem(text) {
      font-size: px-to-rem(20);
      color: $main-text-color;
      transition: color .4s;
    }
    @include elem(subtext) {
      font-size: px-to-rem(20);
    }
    &:hover {
      svg {
        margin-right: px-to-rem(11);
      }

      @include block(globalnav) {
        @include elem(search) {
          @include elem(text) {
            color: rgba($dark-blue-grey, .38);
          }
        }
      }
    }
  }
  @include elem(list) {
    display: none;
    font-size: 0;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    user-select: none;
    z-index: 1;
    @include media-breakpoint-up(tablet) {
      display: flex;
    }
    @include elem(item) {
      display: inline-block;
      height: px-to-rem($header-nav-height);
      line-height: px-to-rem($header-nav-height);
      margin-left: px-to-rem(17);
      position: relative;
      &:not(:last-child) {
        margin-right: px-to-rem(17);
      }
      &.search__item {
        @include media-breakpoint-up(wide) {
          display: none;
        }

        svg {
          font-size: px-to-rem(18);
          height: #{px-to-rem($header-nav-height)};
          vertical-align: 0;
        }
      }
      @include block(badge) {
        top: px-to-rem(44);
      }
    }
    @include elem(button) {
      align-items: center;
      display: flex;
      @include block(globalnav) {
        @include elem(list) {
          @include elem(link) {
            border: {
              color: rgba($dark-blue-grey, .6);
              radius: px-to-rem(17);
              style: solid;
              width: 1px;
            }
            line-height: 0;
            padding: px-to-rem(17) px-to-rem(20) px-to-rem(15);
            transition: background-color .4s, border-color .4s, color .4s;
            &:hover {
              background-color: rgba($dark-blue-grey, .02);
              border-color: rgba($dark-blue-grey, .15);
              color: $main-text-color;
            }
          }
        }
      }
      @include state(active) {
        border-bottom: px-to-rem(4) solid $white;
        height: px-to-rem($header-nav-height - 4px);
        @include elem(link) {
          color: $white;
        }
      }
    }
    @include elem(link) {
      color: $main-text-color;
      cursor: pointer;
      display: block;
      font: {
        size: px-to-rem(14);
        weight: $font-weight-bold;
      }
      position: relative;
      transition: color .4s;
      text-decoration: none;
    }
    @include elem(icon) {
      svg {
        font-size: px-to-rem(18);
        height: px-to-rem(126);
        vertical-align: top;
      }
    }
  }
  @include elem(mobile_item) {
    bottom: 0;
    position: absolute;
    top: 0;
    transition: opacity .4s;
    @include media-breakpoint-up(tablet) {
      display: none;
    }
    ~ .globalnav__mobile_item {
      right: 0;
    }
    @include elem(search) {
      background: transparent;
      border: 0;
      color: $main-text-color;
      cursor: pointer;
      display: block;
      font-size: px-to-rem(24);
      height: px-to-rem(24);
      line-height: 1;
      padding: px-to-rem(13);
      position: relative;
      top: 0;
      user-select: none;
    }
    @include elem(menu) {
      @extend .globalnav__mobile_item__search;
    }
  }
  @include mod(colored) {
    @include block(globalnav) {
      @include elem(logo) {
        color: $white;
        fill: $white;
      }
      @include elem(search) {
        @include media-breakpoint-up(wide) {
          color: rgba($white, .38);
        }
        svg {
          color: $white;
        }
        @include elem(text) {
          color: $white;
        }
        &:hover {
          @include block(globalnav) {
            @include elem(search) {
              @include elem(text) {
                color: rgba($white, .38);
              }
            }
          }
        }
      }
      @include elem(list) {
        @include elem(button) {
          @include block(globalnav) {
            @include elem(list) {
              @include elem(link) {
                border-color: rgba($white, .6);
                &:hover {
                  background-color: rgba($white, .1);
                  border-color: rgba($white, .38);
                  color: $white;
                }
              }
            }
          }
        }
        @include elem(link) {
          color: $white;
        }
      }
      @include elem(mobile_item) {
        @include elem(search) {
          color: $white;
        }
      }
    }
  }
  @include mod('blue') {
    background-color: $header-blue;
    @include elem(container) {
      border-bottom: 0 !important;
    }
  }
  @include mod('red') {
    background-color: $header-red;
    @include elem(container) {
      border-bottom: 0 !important;
    }
  }
  @include mod(sheer) {
    background: linear-gradient(180deg, rgba($black, .4), transparent);
    left: 0;
    right: 0;
    top: 0;
    @include media-breakpoint-down(phone) {
      border-bottom: 1px solid $alpha-white-border-color;
    }
    @include block(globalnav) {
      @include elem(container) {
        @include media-breakpoint-up(tablet) {
          border-bottom: 1px solid $alpha-white-border-color;
        }
      }
    }
  }
  @include elem(subnav) {
    background-color: $white;
    border-radius: px-to-rem(2);
    box-shadow: 0 -(px-to-rem(10)) px-to-rem(20) transparent;
    color: $main-text-color;
    display: block;
    font-size: px-to-rem(18);
    left: 50%;
    line-height: 1.5;
    margin: {
      left: -(px-to-rem(150));
    }
    opacity: 0;
    padding-top: 0;
    pointer-events: none;
    position: absolute;
    text-align: left;
    top: px-to-rem(100);
    transform: translateY(px-to-rem(10));
    transition: opacity .4s, transform .4s, box-shadow 1.2s, -webkit-transform .4s;
    width: px-to-rem(300);
    z-index: 5001;
    &::before {
      border: .8rem solid transparent;
      border-bottom-color: $white;
      content: '';
      display: block;
      height: 0;
      left: 50%;
      margin-left: -.8rem;
      position: absolute;
      top: -1.6rem;
      width: 0;
    }
    @include state(visible) {
      box-shadow: 0 px-to-rem(30) px-to-rem(90) rgba($black, .4);
      opacity: 1;
      pointer-events: all;
      transform: translateY(0);
    }
    @include elem(header) {
      border-bottom: 1px solid $light-border-color;
      font: {
        size: px-to-rem(12);
        weight: $font-weight-semibold;
      }
      line-height: px-to-rem(60);
      margin: 1rem px-to-rem(30) 0;
      text-transform: uppercase;
    }
    @include elem(feature) {
      border-bottom: 1px solid $light-border-color;
      display: block;
      overflow: hidden;
      padding: px-to-rem(30) px-to-rem(30) px-to-rem(20);
      position: relative;
      @include elem(image) {
        display: block;
        float: left;
        height: auto;
        margin-right: px-to-rem(26);
        width: px-to-rem(80);
      }
      @include elem(text) {
        float: left;
        line-height: 1;
        padding-top: px-to-rem(15);
      }
      @include elem(title) {
        color: $main-text-color;
        font-size: px-to-rem(16);
        transition: color .4s linear;
      }
      @include elem(subtitle) {
        color: $alt-text-color;
        font-size: px-to-rem(11);
        font-weight: $font-weight-bold;
        margin-top: px-to-rem(4);
        text-transform: uppercase;
      }
      + .globalnav__subnav__list {
        padding-top: 0;
      }
    }
    @include elem(list) {
      list-style: none;
      padding: {
        bottom: px-to-rem(12);
        top: px-to-rem(28);
      }
    }
    @include elem(item) {
      cursor: pointer;
      font-size: px-to-rem(16);
      line-height: 1;
      margin: {
        left: px-to-rem(30);
        right: px-to-rem(30);
      }
      position: relative;
      &:not(:first-child) {
        border-top: 1px solid $light-border-color;
      }
    }
    @include elem(link) {
      color: $main-text-color;
      cursor: pointer;
      display: block;
      padding-bottom: px-to-rem(8);
      padding-top: px-to-rem(10);
      position: relative;
      &:after {
        background-color: transparent;
        content: '';
        height: calc(100% + #{px-to-rem(2)});
        left: -(px-to-rem(30));
        position: absolute;
        right: -(px-to-rem(30));
        top: -(px-to-rem(1));
        transition: background-color .2s linear;
        width: calc(100% + #{px-to-rem(60)});
        z-index: -1;
      }
      &:active::after,
      &:focus::after,
      &:hover::after {
        background-color: $alt-light-background;
      }
      > svg {
        height: .8em !important;
        margin-right: px-to-rem(8);
      }
      span {
        font: {
          size: px-to-rem(14);
        }
      }
    }
    @include elem(button) {
      @include after-chevron-animation();
      appearance: none;
      backface-visibility: hidden;
      background-color: $brand-color;
      border: 0;
      border-radius: 0 0 px-to-rem(2) px-to-rem(2);
      color: $white;
      cursor: pointer;
      display: block;
      font: {
        size: px-to-rem(12);
        weight: $font-weight-bold;
      }
      line-height: 1;
      outline: none;
      padding: {
        bottom: px-to-rem(19);
        left: px-to-rem(30);
        right: px-to-rem(30);
        top: px-to-rem(23);
      }
      text: {
        align: center;
        decoration: none;
        transform: uppercase;
      }
      transition: color .4s, border .4s, background-color .4s;
      vertical-align: middle;
      white-space: nowrap;
      > svg {
        height: 1em !important;
      }
    }
    @include elem(footnote) {
      border-top: 1px solid $light-border-color;
      display: block;
      overflow: hidden;
      padding: px-to-rem(4) px-to-rem(30) px-to-rem(20);
      position: relative;
      @include elem(text) {
        float: left;
        line-height: 1;
        padding-top: px-to-rem(15);
      }
      @include elem(title) {
        color: $main-text-color;
        font-size: px-to-rem(16);
        transition: color .4s linear;
      }
    }
  }
  @include mod(nonav) {
    border-bottom: 0 !important;
    @include block(globalnav) {
      @include elem(container) {
        border-bottom: 0;
      }
      @include elem(logo) {
        margin: 0 auto;
      }
    }
  }

}

@include block(mobilenav) {
  background-color: $white;
  bottom: 0;
  height: 100vh;
  max-width: 75vw;
  opacity: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  padding: px-to-rem(30);
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(30%);
  transition: transform .2s, opacity .2s, -webkit-transform .2s, z-index .4s;
  width: px-to-rem(250);
  z-index: 1;
  @include elem(list) {
    transform: translateX(30%);
    transition: transform .4s .1s, opacity .4s .1s, -webkit-transform .4s .1s;
  }
  @include state(visible) {
    opacity: 1;
    transform: translateX(0);
    transition: transform .4s, opacity .4s, -webkit-transform .4s, z-index 0s;
    z-index: 10000;
    .mobilenav__list {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @include elem(item) {
    border-bottom: 1px solid $border-color;
    position: relative;
    @include state(clicked) {
      .mobilenav__link {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  @include elem(link) {
    color: $main-text-color;
    display: block;
    font-size: px-to-rem(18);
    padding: px-to-rem(16) 0 px-to-rem(11);
    position: relative;
    svg {
      color: inherit;
      float: right;
      height: px-to-rem(10);
      margin-top: px-to-rem(6);
      transition: transform .4s linear, -webkit-transform .4s linear;
      width: px-to-rem(10);
    }
  }
  @include elem(subnav) {
    max-height: 0;
    overflow: hidden;
    transition: max-height .4s;
    @include state(expanded) {
      max-height: 700px;
    }
    @include elem(feature) {
      border-bottom: 1px solid $border-color;
      display: block;
      overflow: hidden;
      padding: {
        bottom: px-to-rem(20);
        left: 0;
        right: 0;
        top: 0;
      }
      position: relative;
      @include elem(image) {
        display: block;
        float: left;
        height: auto;
        margin-right: px-to-rem(14);
        width: px-to-rem(60);
      }
      @include elem(text) {
        float: left;
        line-height: 1;
        padding-top: px-to-rem(8);
      }
      @include elem(title) {
        color: $main-text-color;
        font-size: px-to-rem(16);
        transition: color .4s linear;
      }
      @include elem(subtitle) {
        color: $main-text-color;
        font: {
          size: px-to-rem(11);
          weight: $font-weight-bold;
        }
        margin-top: .4rem;
        text-transform: uppercase;
      }
    }
    @include elem(list) {
      padding: 0;
    }
    @include elem(item) {
      font-size: px-to-rem(16);
      line-height: 1;
      margin: {
        left: 0;
        right: 0;
      }
      position: relative;
      width: 100%;
      &:not(:first-child) {
        border-top: 1px solid $light-border-color;
      }
    }
    @include elem(link) {
      color: $alt-text-color;
      display: block;
      padding: px-to-rem(10) px-to-rem(15);
      position: relative;
      &::after {
        background-color: transparent;
        content: '';
        height: calc(100% + #{px-to-rem(2)});
        left: -(px-to-rem(30));
        position: absolute;
        right: -(px-to-rem(30));
        top: -(px-to-rem(1));
        transition: background-color .2s linear;
        width: calc(100% + #{px-to-rem(60)});
        z-index: -1;
      }
      &:hover {
        &::after {
          background-color: $alt-light-background;
        }
      }
    }
  }
}

@include block(subnav) {
  @include clearfix-only-after();
  background-color: $white;
  border-top: 1px solid $light-border-color;
  font-size: 0;
  height: px-to-rem(50);
  margin: 0 0 px-to-rem(48) 0;
  padding: 0;
  position: relative;
  transform: translateZ(0);
  width: 100% !important;
  z-index: 20;
  @include media-breakpoint-up(tablet) {
    height: px-to-rem(80);
  }
  @include elem(shadow) {
    background-color: $white;
    &::after {
      @include bottom-shadow();
      left: 25%;
      width: 50%;
    }
  }
  @include elem(container) {
    &::before {
      background: linear-gradient(90deg, $white 0, rgba($white, 0));
      left: 0;
      bottom: 0;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: px-to-rem(15);
    }
    &::after {
      background: linear-gradient(270deg, $white 0, rgba($white, 0));
      right: 0;
      bottom: 0;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: px-to-rem(15);
    }
  }
  @include elem(list) {
    margin: 0px;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0px;
    text-align: center;
    transition: opacity 400ms;
    white-space: nowrap;
    width: 100%;
    height: px-to-rem(50);
    @include media-breakpoint-up(tablet) {
      height: px-to-rem(80);
    }
    @include elem(item) {
      display: inline-block;
      line-height: 50px;
      margin: 0px;
      padding: 0px;
      @include media-breakpoint-up(tablet) {
        height: 100%;
        line-height: px-to-rem(80);
      }
      @include state(active) {
        border-bottom: 2px solid $blue;
      }
    }
  }
  a {
    color: $main-text-color;
    cursor: pointer;
    display: block;
    font-size: px-to-rem(11);
    font-weight: $font-weight-bold;
    padding: px-to-rem(2) px-to-rem(15) 0;
    text-transform: uppercase;
    transition: color .4s ease-in-out;
    @include media-breakpoint-up(tablet) {
      font-size: px-to-rem(12);
      height: 100%;
    }

    &:hover {
      color: $brand-color;
    }
  }
}

@include block(primary-nav) {
  @include clearfix-only-after();
  background-color: $white;
  font-size: 0;
  height: px-to-rem(56);
  margin: 0 0 px-to-rem(16) 0;
  padding: 0;
  position: relative;
  transform: translateZ(0);
  width: 100% !important;
  z-index: 20;
  @include elem(shadow) {
    background-color: $white;
    &::after {
      @include bottom-shadow();
      left: 25%;
      width: 50%;
    }
  }
  @include elem(container) {
    &::before {
      background: linear-gradient(90deg, $white 0, rgba($white, 0));
      left: 0;
      bottom: 0;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: px-to-rem(15);
    }
    &::after {
      background: linear-gradient(270deg, $white 0, rgba($white, 0));
      right: 0;
      bottom: 0;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: px-to-rem(15);
    }
  }
  @include elem(list) {
    height: px-to-rem(56);
    margin: 0px;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0px;
    text-align: center;
    transition: opacity 400ms;
    white-space: nowrap;
    width: 100%;
    @include elem(item) {
      color: $alt-text-color;
      display: inline-block;
      line-height: px-to-rem(56);
      margin: 0px;
      padding: 0px;
      @include media-breakpoint-up(tablet) {
        height: 100%;
      }
      @include mod(active) {
        border-bottom: 2px solid $blue;
        > a {
          color: $main-text-color !important;
        }
      }
    }
  }
  a {
    color: $alt-text-color;
    cursor: pointer;
    display: block;
    font: {
      size: px-to-rem(11);
      weight: $font-weight-bold;
    }
    padding: px-to-rem(2) px-to-rem(15) 0;
    text-transform: uppercase;
    transition: color .4s ease-in-out;
    @include media-breakpoint-up(tablet) {
      font-size: px-to-rem(12);
      height: 100%;
    }

    &:hover {
      color: $brand-color;
    }
  }
}
