.font-primary {
  font-family: $font-family-main;
}

.font-secondary {
  font-family: $font-family-secondary;
}

$colors-list: (
        'main': $dark-blue-grey,
        'light-blue-grey': $light-blue-grey,
        'light-grey': $light-grey,
        'sublight-grey': $sublight-grey,
        'ultralight-grey': $ultralight-grey,
        'light-blue': $light-blue,
        'blue': $blue,
        'red': $red,
        'white': $white,
        'black': $black,
        'green': $green,
        'yella': $yella,
        'purple': $purple
);

@each $name, $var in $colors-list {
  .text-#{$name} {
    color: $var !important;
  }
}

.fullsize {
  @include fullscreen();
  position: absolute;
}
