@include block (drawer) {
  background-color: rgba(0, 0, 0, .5);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity .3s;
  z-index: -100;
  @include elem(actions) {
    display: none;
    @include media-breakpoint-up(desktop) {
      display: block;
      position: absolute;
      transition: right .8s ease-out, left .8s ease-out;
      right: -#{px-to-rem(32)};
      top: 50%;
      transform: translateY(-50%);
    }
  }
  @include elem(close) {
    background-color: $white;
    border-radius: 50%;
    border: 0;
    box-shadow: 0 #{px-to-rem(22)} #{px-to-rem(45)} rgba(0, 0, 0, .7);
    cursor: pointer;
    height: px-to-rem(32);
    text-align: center;
    transition: transform .4s 1s;
    user-select: none;
    width: px-to-rem(32);
    @include media-breakpoint-up(desktop) {
      font-size: px-to-rem(26);
      height: px-to-rem(72);
      width: px-to-rem(72);
    }
  }
  @include elem(body) {
    background: $white;
    bottom: 0;
    box-shadow: -#{px-to-rem(5)} 0 px-to-rem(32) rgba(0, 0, 0 , .5);
    overflow-y: auto;
    padding: px-to-rem(8);
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(10%);
    transition: transform .4s, opacity .3s;
    width: 100%;
    @include media-breakpoint-up(desktop) {
      width: 35%;
    }
  }
  @include elem(header) {
    @include elem(close) {
      cursor: pointer;
      display: none;
      height: px-to-rem(32);
      left: px-to-rem(20);
      position: absolute;
      top: px-to-rem(20);
      width: px-to-rem(32);
      z-index: 2;
      svg {
          height: 1.5em;
          width: 1.5em;
      }
      @include media-breakpoint-down(desktop) {
        display: block;
      }
    }
  }
  @include elem(content) {
    height: 100%;
    position: relative;
  }
  @include mod(active) {
    opacity: 1;
    z-index: 1000;
    @include block(drawer) {
      @include elem(body) {
        transform: translateX(0);
      }
      @include elem(actions) {
        right: calc(100% - #{px-to-rem(40)});
        @include media-breakpoint-up(desktop) {
          right: calc(35% + #{px-to-rem(32)});
        }
      }
    }

  }
}
