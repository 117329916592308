@mixin optimize-read() {
  text-rendering: optimizeLegibility;
}

@mixin antialias-font() {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@mixin heading($size, $responsive: true) {
  $realsize: map_get($heading-sizes, $size);
  color: $main-text-color;
  font: {
    family: $font-family-main;
    size: px-to-rem($realsize);
    weight: $font-weight-bold;
  }
  $spacing: ((($realsize - $font-size-base)/$font-size-base)/2) * 1px;
  letter-spacing: -($spacing);
  line-height: 1.3;
  @if ($responsive) {
    @include media-breakpoint-down(tablet) {
      font-size: px-to-rem($realsize / 1.25);
    }
  }
}

@mixin heading-display($size, $responsive: true) {
  @include heading($size, $responsive);
  $realsize: map_get($heading-sizes, $size);
  font: {
    weight: $font-weight-light;
  }
  @if ($responsive) {
    @include media-breakpoint-up(tablet) {
      font-size: px-to-rem($realsize * 1.25);
    }
  }
}

@mixin heading-entry($size, $responsive: true) {
  @include heading($size);
  $realsize: (map_get($heading-sizes, $size) * 1.35);
  font: {
    size: px-to-rem($realsize);
  }
  @if ($responsive) {
    @include media-breakpoint-down(tablet) {
      font-size: px-to-rem($realsize / 1.35);
    }
  }
}

@mixin heading-underliner() {
  position: relative;
  padding-bottom: px-to-rem(16);
  &:after {
    border-bottom: px-to-rem(2) solid $red;
    bottom: 0;
    content: " ";
    display: block;
    left: 50%;
    margin-left: -(px-to-rem(15));
    position: absolute;
    width: px-to-rem(30);
  }
}

@mixin subheading($responsive: true) {
  color: $alt-text-color;
  font: {
    size: px-to-rem(20);
    weight: $font-weight-light;
  }
  line-height: 1.5;
  margin: px-to-rem(12) 0 px-to-rem(32);
  @if ($responsive) {
    @include media-breakpoint-up(tablet) {
      font: {
        size: px-to-rem(22);
      }
      margin: px-to-rem(16) 0 px-to-rem(32);
    }
  }
}

@mixin eyebrow($responsive: true) {
  color: $brand-color;
  font: {
    size: px-to-rem(10);
    weight: $font-weight-bold;
  }
  margin-bottom: px-to-rem(8);
  text-transform: uppercase;
  @if ($responsive) {
    @include media-breakpoint-up(tablet) {
      font-size: px-to-rem(11);
    }
  }
}

@mixin paragraph($responsive: true) {
  color: $main-text-color;
  font: {
    size: px-to-rem(16);
  }
  line-height: 1.625;
  @if ($responsive) {
    @include media-breakpoint-up(tablet) {
      font-size: px-to-rem(20);
      line-height: 1.8;
    }
  }
}

@mixin smallParagraph($responsive: true) {
  color: $alt-text-color;
  font: {
    family: $font-family-main !important;
    size: px-to-rem(13);
  }
  line-height: 1.8;
  @if ($responsive) {
    @include media-breakpoint-up(phone) {
      font-size: px-to-rem(15);
    }
  }
}

@mixin feature($responsive: true) {
  color: $main-text-color;
  font: {
    family: $font-family-main;
    size: px-to-rem(18);
    weight: $font-weight-light;
  }
  line-height: 1.5;
  margin: {
    bottom: px-to-rem(25);
  }
  @if ($responsive) {
    @include media-breakpoint-up(tablet) {
      font: {
        size: px-to-rem(28);
      }
      margin: {
        bottom: px-to-rem(42);
      }
    }
  }
}

@mixin list-maintext() {
  color: $main-text-color;
  font: {
    size: px-to-rem(15);
    weight: $font-weight-bold;
  }
  line-height: 1.3;
  text-transform: none;
  margin: {
    bottom: 0;
    top: 0;
  }
  padding: 0;
  transition: color .4s ease;
  &:hover {
    color: $link-color;
  }
}

@mixin list-subtext() {
  color: $light-text-color;
  font: {
    size: px-to-rem(12);
    weight: $font-weight-bold;
  }
  line-height: px-to-rem(12);
  margin: {
    bottom: 0;
    top: px-to-rem(5);
  }
  padding: 0;
}

@mixin caption() {
  font: {
    family: $font-family-main;
    size: px-to-rem(12);
  }
  line-height: 1.4;
  text-align: center;
}

@mixin list($responsive: true) {
  color: $main-text-color;
  font: {
    family: $font-family-secondary;
    size: px-to-rem(16);
  }
  line-height: 1.625;
  @if ($responsive) {
    @include media-breakpoint-up(tablet) {
      font-size: px-to-rem(20);
      line-height: 1.8;
    }
  }
}

@mixin link() {
  background-image: linear-gradient(0deg, transparent, transparent 2px, rgba($main-text-color, .4) 0, rgba($main-text-color, .4) 3px, transparent 0);
  color: $main-text-color;
  position: relative;
  text-decoration: none;
  transition: color .4s ease;
  text-shadow: -1px -1px 0 $white, 1px -1px 0 $white, -1px 1px 0 $white, 1px 1px 0 $white;
  &:hover {
    background-image: linear-gradient(0deg, transparent, transparent 2px, rgba($brand-color, .4) 0, rgba($brand-color, .4) 3px, transparent 0);
    color: $brand-color;
  }
}

@mixin link-hover($color: $brand-color) {
  transition: color .4s ease-in-out;
  &:hover,
  &:focus,
  &:active {
    color: $color;
  }
}

@mixin default-link($color: $brand-color) {
  color: $color;
  cursor: pointer;
  text-decoration: none;
  transition: color .200s ease-in-out;
  @include link-hover();
}

@mixin date() {
  color: $light-grey;
  font: {
    family: $font-family-main;
    size: px-to-rem(12);
    weight: $font-weight-bold;
  }
  line-height: 1.2;
  text-transform: uppercase;
}

// HELPERS

%heading {
  @include heading-display(h2);
  font: {
    weight: $font-weight-bold;
  }
  margin-bottom: px-to-rem(60);
  padding-bottom: px-to-rem(15);
}

%heading-reduced {
  @include heading-display(h4);
  font: {
    weight: $font-weight-light;
  }
  padding-bottom: px-to-rem(30);
}

%headline {
  @include heading-display(h2);
  @include heading-underliner();
  margin-bottom: px-to-rem(60);
  padding-bottom: px-to-rem(15);
  text-align: center;
}

%text-elevation-1 {
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
}
