//
// BREAKPOINT
//
$media-breakpoints: (
  fluid: 0,
  phone: 576px,   // 36em
  tablet: 768px,  // 48em
  desktop: 992px, // 62em
  wide: 1200px,   // 75em
  hires: 1416px   // 112em
) !default;

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

$content-max-width: 1280px;
$minicontent-max-width: 640px;
$article-max-width: 640px;
$modal-max-width: 720px;
$checkout-max-width: 840px;
$marketing-article-max-width: 1024px;

//
// FONTS
//
$font-family-main: 'Benton Sans', Helvetica Neue, Helvetica, Arial, sans-serif;
$font-family-secondary: 'Miller Daily', Georgia, Times, Times New Roman, serif;
$font-family-monospace: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;

$font-size-base: 16px !default; // Browser default, 1rem normal
$line-height-base: 1.5 !default;

$heading-sizes: (
  h1: 48px,
  h2: 34px,
  h3: 28px,
  h4: 24px,
  h5: 18px,
  h6: 16px
) !default;

$font-weight-light: 300 !default;
$font-weight-normal: normal !default;
$font-weight-semibold: 500 !default;
$font-weight-bold: 600 !default;

$header-nav-height: 130px;
$header-slim-nav-height: 50px;

//
// ICONS
//
$icons: 'chevron-down', 'lp-logo';

//
// BEM
//
$combined-state-selectors: true !default;
$element-separator: "__" !default;
$modifier-separator: "--" !default;
$state-prefix: "is" !default;
