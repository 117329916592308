.text-elevation-1 {
  @extend %text-elevation-1;
}

.is-visible {
  display: block;
  opacity: 1;
  visibility: visible;
}

.is-hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}

@include block(sticky) {
  position: fixed;
  top: 0;
  transform: translate3d(0, 0, 0);
  z-index: 100;
}

@include block(d-flex) {
  display: flex;
}

@include block(d-align-center) {
  align-items: center;
}

@include block(justify-content-center) {
  justify-content: center;
}

@include block(image--zoom) {
  @include imageZoom();
}

@include block(visually-hidden) {
  left: -99999px;
  position: absolute;
  top: 0;
}

@include block(d-block) {
  display: block;
}

@include block(narrative-text) {
  margin-bottom: px-to-rem(64);
  @include elem(title) {
    @include heading-entry(h3);
    margin-bottom: px-to-rem(8);
  }
  @include elem(smalltitle) {
    @include heading-entry(h4);
    margin-bottom: px-to-rem(8);
  }
  @include elem(subtitle) {
    @include heading-display(h3, false);
    margin-bottom: px-to-rem(24);
  }
  ul {
    li {
      @include paragraph();
      font-family: $font-family-secondary;
      list-style-type: disc;
      margin: 0 0 px-to-rem(16) px-to-rem(24);
    }
  }
  ol {
    list-style: inherit;
    list-style-type: lower-alpha;
    li {
      @include paragraph();
      font-family: $font-family-secondary;
      margin: 0 0 px-to-rem(16) px-to-rem(24);
    }
  }
  > p {
    font-family: $font-family-secondary;
    margin-bottom: px-to-rem(32);
    &:first-of-type {
      margin-top: px-to-rem(16);
    }
    &:last-of-type {
      margin-bottom: px-to-rem(32);
    }
  }
}

@each $prop, $abbrev in (margin: m, padding: p) {
  @for $i from 0 to 6 {
    $size: px-to-rem($i * $font-size-base);
    .#{$abbrev}-#{$i} {
      #{$prop}: $size !important;
    }
    .#{$abbrev}t-#{$i} {
      #{$prop}-top: $size !important;
    }
    .#{$abbrev}r-#{$i} {
      #{$prop}-right: $size !important;
    }
    .#{$abbrev}b-#{$i} {
      #{$prop}-bottom: $size !important;
    }
    .#{$abbrev}l-#{$i} {
      #{$prop}-left: $size !important;
    }
    .#{$abbrev}x-#{$i} {
      #{$prop}-right: $size !important;
      #{$prop}-left: $size !important;
    }
    .#{$abbrev}y-#{$i} {
      #{$prop}-top: $size !important;
      #{$prop}-bottom: $size !important;
    }

  }
}

@include block(text-center) {
  text-align: center;
}

@include block(text-right) {
  text-align: right;
}

@include block(text-left) {
  text-align: left;
}

@include block(text-small) {
  font-size: smaller;
}

@include block(flex-grow-0) {
  flex-grow: 0;
}

@each $breakpoint, $size in $media-breakpoints {
  .hide_at_#{$breakpoint} {
    @include media-breakpoint-up(#{$breakpoint}) {
      display: none
    }
  }
  .hide_to_#{$breakpoint} {
    @include media-breakpoint-down(#{$breakpoint}) {
      display: none
    }
  }
}

