@include block(search) {
  background-color: $white;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity .4s;
  z-index: -1;
  @include mod(visible) {
    transition: opacity .3s;
    opacity: 1;
    z-index: 8000;
  }
  @include elem(inner) {
    @include container();
    height: px-to-rem($header-slim-nav-height);
    position: relative;
    @include media-breakpoint-up(tablet) {
      height: px-to-rem($header-nav-height);
    }
  }
  @include elem(label) {
    bottom: 0;
    color: $main-text-color;
    display: block;
    font-size: px-to-rem(18);
    height: px-to-rem(18);
    left: 0;
    line-height: 1;
    margin: auto 0;
    position: absolute;
    top: 0;
    width: px-to-rem(18);
    @include media-breakpoint-up(tablet) {
      top: -#{px-to-rem(4)};
    }
  }
  @include elem(input) {
    border: 0;
    bottom: 0;
    color: $main-text-color;
    font: {
      size: px-to-rem(24);
      weight: $font-weight-light;
    }
    height: px-to-rem(38);
    left: px-to-rem(37);
    margin-bottom: auto;
    margin-top: auto;
    outline: 0;
    position: absolute;
    right: px-to-rem(59);
    top: 0;
    width: calc(100% - #{px-to-rem(50)} - #{px-to-rem(9)});
    &::placeholder {
      color: rgba($main-text-color, .2);
    }
  }
  @include elem(close) {
    background-color: transparent;
    border: 0;
    bottom: 0;
    color: $alt-text-color;
    cursor: pointer;
    display: block;
    font-size: px-to-rem(18);
    height: px-to-rem(38);
    margin-bottom: auto;
    margin-top: auto;
    outline: 0;
    padding: 0;
    position: absolute;
    right: -#{px-to-rem(9)};
    top: 0;
    transition: color .4s;
    width: px-to-rem(38);
  }
}

@include block(search-results) {
  border-bottom: 1px solid $border-color;
  margin: px-to-rem(80) 0;
  padding-bottom: px-to-rem(80);
  &:last-of-type {
    border-bottom: 0;
  }
  @include block(button) {
    @include auto-center();
  }
  @include elem(headtext) {
    margin-bottom: px-to-rem(32);
    text-align: left !important;
    @include media-breakpoint-down(desktop) {
      text-align: center !important;
    }
    span {
      color: $alt-text-color;
      font-size: px-to-rem(24);
      @include media-breakpoint-down(wide) {
        font-size: px-to-rem(14);
      }
    }
    @include block(button) {
      vertical-align: text-bottom;
    }
  }
  @include elem(headbutton) {
    @include media-breakpoint-down(desktop) {
      display: none;
    }
  }
  @include elem(counter) {
    font-family: $font-family-secondary;
    margin-top: px-to-rem(16);
  }
}
