@include block(top-choices) {
  background-color: $white;
  bottom: 0;
  box-shadow: 0 px-to-rem(8) px-to-rem(50) rgba($black, .5);
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateY(15%);
  transition: transform .4s, opacity .4s;
  z-index: 0;
  @include media-breakpoint-up(tablet) {
    top: auto;
  }
  @include elem(container) {
    background-color: $white;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    max-width: px-to-rem($content-max-width);
    overflow-x: hidden;
    overflow-y: auto;
    padding: px-to-rem(30) px-to-rem(30) 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1000;
    @include media-breakpoint-up(tablet) {
      bottom: auto;
      left: auto;
      max-height: calc(100vh - #{px-to-rem(132)});
      padding: px-to-rem(40) px-to-rem(60) px-to-rem(60);
      position: relative;
      right: auto;
      top: auto;
    }
    @include media-breakpoint-up(hires) {
      padding: {
        left: 0;
        right: 0;
      }
    }
  }
  @include elem(wrap) {
    @include media-breakpoint-up(tablet) {
      display: flex;
    }
  }
  @include elem(column) {
    @include auto-center();
    max-width: px-to-rem($article-max-width);
    width: 100%;
    @include media-breakpoint-down(tablet) {
      margin-bottom: px-to-rem(32);
    }
    @include media-breakpoint-up(tablet) {
      &:nth-of-type(2) {
        margin-left: px-to-rem(32);
      }
    }
  }
  @include elem(list) {
    counter-reset: tlist;
    position: relative;
    text-align: left;
    @include elem(heading) {
      @include subheading();
      color: $main-text-color;
      margin: 0 !important;
      margin-bottom: px-to-rem(8) !important;
    }
    @include elem(content) {
      border-top: 1px solid $light-border-color;
      padding-right: 0;
      position: relative;
      @include media-breakpoint-up(tablet) {
        padding-right: 35%;
      }
    }
    @include elem(primary) {
      list-style: none;
      padding: {
        right: px-to-rem(30);
        top: px-to-rem(10);
      }
      @include media-breakpoint-down(phone) {
        display: none;
      }
      @include elem(item) {
        &:not(:first-child) {
          margin-top: px-to-rem(10);
        }
        @include elem(link) {
          align-items: center;
          color: $main-text-color;
          display: flex;
          font-size: px-to-rem(16);
          height: px-to-rem(60);
          padding-left: px-to-rem(110);
          position: relative;
          transition: color .4s;
          &:hover {
            color: $link-color;
          }
        }
      }
    }
    @include elem(secondary) {
      @include clearfix();
      border-bottom: 1px solid $alt-light-background;
      bottom: 0;
      font: {
        size: px-to-rem(12);
        weight: 600;
      }
      line-height: px-to-rem(28);
      list-style: none;
      padding: px-to-rem(10) 0 px-to-rem(10) px-to-rem(30);
      position: relative;
      right: 0;
      top: 0;
      width: 100%;
      @include media-breakpoint-up(tablet) {
        height: px-to-rem(210);
        position: absolute;
        width: 35%;
      }
      @include elem(item) {
        position: relative;
        @include media-breakpoint-up(tablet) {
          &:nth-child(-n+3) {
            display: none;
          }
        }
        &:before {
          color: $alt-text-color;
          content: counter(tlist) ".";
          counter-increment: tlist;
          display: block;
          font: {
            family: $font-family-secondary;
            weight: $font-weight-bold;
          }
          left: -#{px-to-rem(40)};
          position: absolute;
          text-align: right;
          top: -1px;
          width: px-to-rem(30);
        }
        @include elem(link) {
          color: $main-text-color;
          display: block;
          height: px-to-rem(28);
          overflow: hidden;
          position: relative;
          text-overflow: ellipsis;
          transition: color .4s;
          white-space: nowrap;
          &:hover {
            color: $link-color;
          }

        }
      }
    }
    @include elem(image) {
      background: 50% no-repeat #3b444f;
      background-size: cover;
      height: px-to-rem(60);
      left: 0;
      position: absolute;
      top: 0;
      width: px-to-rem(80);
    }
    @include elem(marker) {
      @include numberMarker(solid);
      counter-increment: tlist;
      left: px-to-rem(68);
      position: absolute;
    }
    @include elem(text) {
      border-bottom: 1px solid $light-border-color;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: px-to-rem(60);
      width: 100%;
    }
    @include elem(title) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    @include elem(subtitle) {
      @include eyebrow();
      color: $light-text-color;
      font-size: px-to-rem(11) !important;
      overflow: hidden;
      margin: 0;
      margin-top: px-to-rem(6);
      padding: 0;
      text-overflow: ellipsis;
    }
    @include elem(more) {
      margin-top: px-to-rem(36);
    }
  }
  @include elem(close) {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: block;
    font-size: px-to-rem(20);
    height: px-to-rem(40);
    line-height: px-to-rem(40);
    outline: 0;
    padding: 0;
    position: absolute;
    right: px-to-rem(16);
    text-align: left;
    top: px-to-rem(16);
    width: px-to-rem(40);
    z-index: 999;
    @include media-breakpoint-up(tablet) {
      background-color: $white;
      border-radius: 50%;
      box-shadow: 0 px-to-rem(22) px-to-rem(46) rgba($black, .75);
      font-size: px-to-rem(26);
      height: px-to-rem(72);
      left: 50%;
      line-height: px-to-rem(72);
      margin-left: -#{px-to-rem(36)};
      text-align: center;
      top: 0;
      transition: transform .4s 1s;
      user-select: none;
      width: px-to-rem(72);
      z-index: 999;
    }
  }
  @include mod(visible) {
    opacity: 1;
    transform: translateY(0);
    z-index: 1000;
    @include block(top-choices) {
      @include elem(close) {
        @include media-breakpoint-up(tablet) {
          transform: translateY(-#{px-to-rem(102)});
          transition: transform .4s .4s;
        }
      }
    }
  }

}
