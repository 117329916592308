@include block(sights) {
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
  @include elem(container) {
    @include flex-width(7.56, desktop);
    @include flex-width(8, wide);
    position: relative;
    width: 100%;
  }
  @include elem(list) {
    @include clearfix-only-after();
  }
  @include elem(item) {
    border-bottom: 1px solid $border-color;
    padding: {
      bottom: px-to-rem(24);
      top: px-to-rem(24);
    }
    position: relative;
    @include media-breakpoint-down(tablet) {
      &:nth-last-child(-n+2) {
        display: none;
      }
    }
    @include media-breakpoint-up(tablet) {
      float: left;
      width: 47.61%;
      &:nth-child(odd) {
        margin-right: 4.76%;
      }
    }
    @include elem(container) {
      align-items: stretch;
      color: $main-text-color;
      display: flex;
      text-decoration: none;
      transition: color .2s ease;
      //&:hover {
      //  .sights__item__text__title {
      //    color: $link-color;
      //  }
      //}
    }
    @include elem(image) {
      align-items: center;
      display: flex;
      margin-right: px-to-rem(8);
      min-width: px-to-rem(105);
      img {
        height: px-to-rem(50);
        width: px-to-rem(80);
      }
    }
    @include elem(text) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include elem(title) {
        @include list-maintext();
      }
      @include elem(location) {
        @include list-subtext();
      }
    }
    @include elem(marker) {
      transform: translateX(-50%);
    }
  }
  @include elem(button) {
    padding-top: px-to-rem(64);
  }
  @include elem(ad-wrap) {
    @include flex-width(4.44, desktop);
    @include flex-width(4, wide);
    position: relative;
    width: 100%;
    @include media-breakpoint-down(tablet) {
      margin-top: px-to-rem(32);
    }
  }

}
