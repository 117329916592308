@include block(pagination) {
  display: flex;
  list-style: none;
  justify-content: center;
  margin-top: px-to-rem(32);
  @include elem(item) {
    background-color: transparent;
    border-radius: 50%;
    color: $alt-text-color;
    cursor: pointer;
    font-size: px-to-rem(14);
    height: px-to-rem(32);
    line-height: px-to-rem(32);
    margin: 0 px-to-rem(8);
    padding: 0 px-to-rem(8) px-to-rem(4);
    text-align: center;
    transition: background-color .4s ease-in-out;
    width: px-to-rem(32);
    &:first-of-type {
      margin-left: 0;
      padding-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
      padding-right: 0;
    }
    @include mod(pages) {
      @include media-breakpoint-down(tablet) {
        display: none;
      }
    }
    @include mod(counter) {
      background-color: transparent !important;
      cursor: default;
      width: auto;
      strong {
        color: $main-text-color;
      }
      @include media-breakpoint-up(desktop) {
        display: none;
      }
    }
    @include mod(prev) {
      .icon {
        margin-left: px-to-rem(6);
      }
    }
    @include mod(next) {
      .icon {
        margin-right: px-to-rem(6);
      }
    }
    a {
      color: $alt-text-color;
      transition: color .4s ease-in-out;
    }
    .icon {
      height: px-to-rem(12);
      width: px-to-rem(12);
    }
    &:hover {
      background-color: $alt-light-background;
      > a {
        color: $brand-color;
      }
    }
    @include mod(active) {
      background-color: $brand-color !important;
      border-radius: 50%;
      border-bottom: 0;
      color: #fff;
      font: {
        weight: $font-weight-bold;
      }
    }
    @include mod(disabled) {
      background-color: transparent !important;
      color: $light-text-color;
      cursor: default;
      @include media-breakpoint-down(tablet) {
        display: none;
      }
    }

  }
}
