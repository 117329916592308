@include block(badge) {
  background: $red;
  border-radius: px-to-rem(10);
  color: $white;
  font: {
    size: px-to-rem(12);
    weight: $font-weight-bold;
  }
  height: px-to-rem(20);
  line-height: px-to-rem(22);
  min-width: px-to-rem(20);
  padding: 0 px-to-rem(6);
  position: absolute;
  text-align: center;
  top: -#{px-to-rem(10)};
  transform-origin: -10% center;
  transform: translateX(-40%);
  white-space: nowrap;
  @include mod(success) {
    background: $green;
  }
  @include mod(main) {
    background: $blue;
  }
}
