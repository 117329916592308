@include block(section) {
  @include section();
  margin: 0;
  @include elem(content-block-divider) {
    align-items: center;
    display: flex;
    height: px-to-rem(90);
  }
  @include elem(container) {
    @include container();
  }
  @include elem(reduced) {
    @include flex-margin(.4, left, wide);
    @include flex-margin(.4, right, wide);
    @include flex-width(11.2, wide);
  }
  @include elem(mini) {
    @include flex-margin(1.5, left, tablet);
    @include flex-margin(1.5, right, tablet);
    @include flex-width(9, tablet);
  }
  @include elem(content) {
    position: relative;
  }
  @include elem(header) {
    padding: {
      bottom: px-to-rem(50);
      top: px-to-rem(24);
    }
    text-align: center;
  }
  @include elem(headtext) {
    @include heading-display(h3, false);
    margin-bottom: px-to-rem(16);
    text-align: center;
  }
  @include elem(headline) {
    @include heading-display(h2);
    @include heading-underliner();
    margin-bottom: px-to-rem(30);
    text-align: center;
    @include media-breakpoint-up(desktop) {
      margin-bottom: px-to-rem(50);
    }
  }
  @include elem(subhead) {
    @include heading-display(h3, false);
    margin-bottom: px-to-rem(36);
    @include media-breakpoint-down(desktop) {
      top: px-to-rem(12);
    }
  }
  @include elem(entry-title) {
    @include heading-entry(h1);
    line-height: 1.2;
  }
  @include elem(entry-smalltitle) {
    @include heading-entry(h2);
    line-height: 1.2;
  }
  @include elem(entry-subtitle) {
    font: {
      family: $font-family-secondary;
      size: px-to-rem(16);
      style: italic;
      weight: $font-weight-normal;
    }
  }
  @include elem(entry-plain-subtitle) {
    @include subheading();
  }
  @include elem(entry-description) {
    @include paragraph();
    font-family: $font-family-secondary;
    margin: {
      bottom: 0;
      left: px-to-rem(16);
      right: px-to-rem(16);
      top: px-to-rem(40);
    }
    @include media-breakpoint-up(desktop) {
      margin: {
        left: auto;
        right: auto;
      }
      max-width: px-to-rem(800);
      width: 70%;
    }
  }
  @include elem(eyebrow) {
    @include eyebrow();
    color: $red;
  }
  @include elem(button) {
    text-align: center;
    padding-bottom: px-to-rem(64);
    @include media-breakpoint-down(tablet) {
      padding-top: px-to-rem(32);
    }
  }
  @include elem(description) {
    color: $alt-text-color;
    font: {
      size: px-to-rem(14);
      weight: $font-weight-normal;
    }
    margin-bottom: px-to-rem(36);
  }
  @include elem(filters) {
    border: {
      top: 1px solid $border-color;
      bottom: 1px solid $border-color;
    }
    margin-bottom: px-to-rem(64);
    padding: px-to-rem(24) 0 px-to-rem(16);
    text-align: center;
  }
  @include mod(border-top) {
    @include section-border();
  }
  @include mod(border-bottom) {
    @include section-border(1, bottom);
  }
  @include mod(half-bottom) {
    @include section(.75);
  }
  @include mod(main) {
    @include section-main();
  }
  @include mod(first) {
    margin: {
      top: px-to-rem(40);
    }
  }
  @include mod(with-nav) {
    @include section-main();
    margin-bottom: 0 !important;
  }
  @include mod(with-space) {
    @include section-main(.5);
    margin-bottom: 0 !important;
  }
  @include mod(flex) {
    display: flex;
  }
  @include mod(login) {
    display: flex;
    justify-content: center;
    align-items: center;
    @include block(section) {
      @include elem(container) {
        @include flex-width(8);
        @include flex-width(4, tablet);
        @include flex-width(3, wide);
        @include auto-center();
      }
    }
  }
  @include mod(catalog) {
    @include block(section) {
      @include elem(subhead) {
        margin-bottom: px-to-rem(8);
      }
    }
  }
  @include mod(cart) {
    margin-top: px-to-rem(32);
  }
  @include mod(search) {
    @include block(product) {
      @include elem(grid) {
        min-width: 100%;
        margin: 0 -#{px-to-rem(8)};
        @include media-breakpoint-down(wide) {
          margin: 0 -1%;
        }
        @include elem(item) {
          @include flex-width(6);
          @include flex-width(3, phone);
          @include flex-width(2, desktop);
        }
      }
    }
    @include block(carousel) {
      width: 100%;
    }
    @include block(section) {
      @include elem(button) {
        padding: {
          bottom: 0;
          top: px-to-rem(32);
        }
      }
    }
  }
  @include mod(top-experiences) {
    + .section--top-sights {
      margin-top: px-to-rem(24);
    }
  }
  @include mod(checkout) {
    margin: 0;
  }

  @include elem(titles) {
    margin-bottom: px-to-rem(56);
    @include elem(title) {
      color: $main-text-color;
      font: {
        family: $font-family-main;
        size: px-to-rem(56);
        weight: $font-weight-bold;
      }
      letter-spacing: -1px;
      line-height: 1.2;
    }
    @include elem(description) {
      color: $alt-text-color;
      font: {
        family: $font-family-main;
        size: px-to-rem(24);
        weight: $font-weight-light;
      }
      letter-spacing: -.7px;
      line-height: 1.3;
    }
  }
}
