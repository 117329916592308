@include block(placehead) {
  color: $white;
  height: 60vh;
  left: 0;
  margin-top: -(px-to-rem(50));
  max-height: 80vh;
  min-height: px-to-rem(600);
  overflow: hidden;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 6;
  @include media-breakpoint-up(tablet) {
    margin-top: -(px-to-rem($header-nav-height));
    height: 80vh;
  }
  @include elem(item) {
    height: 100%;
    width: 100%;
  }
  @include elem(image-container) {
    @include fullscreen();
    background: {
      color: $alt-dark-background;
      position: center center;
      repeat: no-repeat;
      size: cover;
    }
    overflow: hidden;
  }
  @include elem(image) {
    @include fullscreen();
    animation: 1s fadeIn, 16s forwards zoomScale;
    background: {
      color: $alt-dark-background;
      position: center center;
      repeat: no-repeat;
      size: cover;
    }
    overflow: hidden;
    transform: translateZ(0);

    @include mod(static) {
      animation: none;
    }

  }
  @include elem(container) {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    z-index: 3;
  }
  @include elem(claim) {
    text-align: center;
    @include media-breakpoint-up(phone) {
      width: 65% !important;
    }
    @include media-breakpoint-up(tablet) {
      bottom: px-to-rem(110) !important;
    }
    a {
      color: $white !important;
    }
    @include elem(eyebrow) {
      @include eyebrow();
      color: $white;
      display: inline-block;
      font: {
        size: px-to-rem(14) !important;
        weight: $font-weight-light;
      }
      text-shadow: $text-shadow;
      margin-bottom: px-to-rem(26);
    }
    @include elem(title) {
      font-size: px-to-rem(70);
      letter-spacing: -(px-to-rem(2));
      line-height: 1;
      @include media-breakpoint-up(phone) {
        font-size: px-to-rem(90);
      }
      @include media-breakpoint-up(desktop) {
        font-size: px-to-rem(120);
        letter-spacing: -(px-to-rem(4));
      }
    }
    @include elem(strapline) {
      color: white;
      font: {
        family: $font-family-secondary;
        size: px-to-rem(20);
        style: italic;
        weight: $font-weight-normal;
      }
      height: px-to-rem(53);
      opacity: 0;
      padding-top: px-to-rem(7);
      position: relative;
      @include media-breakpoint-up(tablet) {
        font-size: px-to-rem(26);
        height: px-to-rem(65);
        padding-top: px-to-rem(12);
      }
    }
    @include elem(callout-link) {
      display: block;
      margin-top: px-to-rem(24);
      cursor: pointer;
      @include media-breakpoint-up(phone) {
        margin-top: px-to-rem(42) !important;
      }
      @include media-breakpoint-up(tablet) {
        opacity: 1;
      }
    }
  }
  @include mod(no-picture) {
    border-bottom: 1px solid $border-color;
    margin-top: 0;
    max-height: 30vh;
    @include block(placehead) {
      @include elem(claim) {
        @include elem(eyebrow) {
          color: $dark-blue-grey;
          a {
            color: $dark-blue-grey !important;
          }
        }
        @include elem(title) {
          color: $dark-blue-grey;
        }
      }
    }
  }

}

@include block(masthead) {
  background: linear-gradient(180deg, rgba($black, .6), transparent);
  color: $white;
  height: 100vh;
  left: 0;
  margin-top: -(px-to-rem(50));
  max-height: 100vh;
  min-height: px-to-rem(600);
  overflow: hidden;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 6;
  @include media-breakpoint-up(tablet) {
    margin-top: -(px-to-rem($header-nav-height));
    height: 100vh;
  }
  @include elem(item) {
    height: 100%;
    width: 100%;
  }
  @include elem(image-container) {
    @include fullscreen();
    background: {
      color: $alt-dark-background;
      position: center center;
      repeat: no-repeat;
      size: cover;
    }
    overflow: hidden;
  }
  @include elem(image) {
    @include fullscreen();
    animation: 1s fadeIn, 16s forwards zoomScale;
    background: {
      color: $alt-dark-background;
      position: center center;
      repeat: no-repeat;
      size: cover;
    }
    overflow: hidden;
    transform: translateZ(0);

    @include mod(static) {
      animation: none;
    }

  }
  @include elem(container) {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    z-index: 3;
  }
  @include elem(claim) {
    text-align: center;
    @include media-breakpoint-up(phone) {
      width: 65% !important;
    }
    @include media-breakpoint-up(tablet) {
      bottom: px-to-rem(110) !important;
    }
    a {
      color: $white !important;
    }
    @include elem(eyebrow) {
      @include eyebrow();
      color: $white;
      display: inline-block;
      font: {
        size: px-to-rem(14) !important;
        weight: $font-weight-normal;
      }
      margin-bottom: px-to-rem(26);
    }
    @include elem(interior) {
      @include auto-center();
      font-size: px-to-rem(40);
      margin-bottom: px-to-rem(8);
      max-width: px-to-rem(288);
    }
    @include elem(title) {
      font-size: px-to-rem(50);
      letter-spacing: -(px-to-rem(2));
      line-height: .95;
      margin-bottom: px-to-rem(24);
      @include media-breakpoint-up(phone) {
        font-size: px-to-rem(70);
      }
      @include media-breakpoint-up(desktop) {
        font-size: px-to-rem(110);
        letter-spacing: -(px-to-rem(4));
      }
      @include mod(elegant) {
        font: {
          family: $font-family-secondary;
          size: px-to-rem(80);
          style: italic;
          weight: $font-weight-light;
        }
        letter-spacing: -2px;
        margin-bottom: px-to-rem(14);
        @include media-breakpoint-up(tablet) {
          font-size: px-to-rem(140);
          margin-bottom: px-to-rem(24);
        }
      }
      @include mod(special) {
        font: {
          family: 'Caveat', cursive;
          size: px-to-rem(100);
          style: italic;
          weight: $font-weight-light;
        }
        letter-spacing: -4px;
        margin-left: -1rem;
        margin-top: -1.8rem;
        @include media-breakpoint-up(tablet) {
          font-size: px-to-rem(160);
          margin-top: -4rem;
        }
      }
      @include mod(specialmarker) {
        font: {
          family: $font-family-main;
          size: px-to-rem(32);
          weight: 300;
        }
        text-transform: uppercase;
        font-stretch: condensed;
        @include media-breakpoint-up(tablet) {
          font-size: px-to-rem(45);
        }
      }

    }
    @include elem(strapline) {
      color: white;
      font: {
        family: $font-family-secondary;
        size: px-to-rem(20);
        style: italic;
        weight: $font-weight-normal;
      }
      height: px-to-rem(53);
      line-height: 1.3;
      position: relative;
      @include media-breakpoint-up(tablet) {
        font-size: px-to-rem(26);
        height: px-to-rem(65);
      }
    }
    @include elem(partnerline) {
      color: white;
      font: {
        size: px-to-rem(16);
        weight: $font-weight-normal;
      }

      position: absolute;
      bottom: 3rem;
      left: 50%;
      transform: translateX(-50%);

      @include media-breakpoint-up(tablet) {
        font-size: px-to-rem(17);
        width: 100%;
      }

      @include elem(logo) {
        margin: auto;
      }
    }
    @include elem(callout-link) {
      display: block;
      margin-top: px-to-rem(24);
      cursor: pointer;
      @include media-breakpoint-up(phone) {
        margin-top: px-to-rem(42) !important;
      }
      @include media-breakpoint-up(tablet) {
        opacity: 1;
      }
    }
  }
  @include mod(no-picture) {
    border-bottom: 1px solid $border-color;
    margin-top: 0;
    max-height: 30vh;
    @include block(placehead) {
      @include elem(claim) {
        @include elem(eyebrow) {
          color: $dark-blue-grey;
          a {
            color: $dark-blue-grey !important;
          }
        }
        @include elem(title) {
          color: $dark-blue-grey;
        }
      }
    }
  }
  @include elem(footer) {
    bottom: 10px !important;
    cursor: pointer !important;
    display: block !important;
    height: px-to-rem(72);
    left: 50% !important;
    position: absolute !important;
    text-align: center !important;
    transform: translateX(-50%) !important;
    @include elem(item) {
      display: flex;
      flex-direction: column;
    }
  }
}
