@include block(marketing-article) {
  @include container();
  border-bottom: 1px solid $border-color;
  max-width: $content-max-width;
  padding: px-to-rem(90) 0 px-to-rem(64);
  @include media-breakpoint-up(tablet) {
    padding: px-to-rem(156) 0 px-to-rem(144);
  }
  &:last-of-type {
    border-bottom: 0;
  }
  @include elem(header) {
    margin-bottom: px-to-rem(32);
    padding-top: px-to-rem(20);
    text-align: center;
    @include media-breakpoint-up(tablet) {
      text-align: left;
    }
  }
  @include elem(icon) {
    margin-bottom: px-to-rem(40);
    svg {
      fill: $brand-color;
      height: px-to-rem(100);
      width: px-to-rem(100);
    }
  }
  @include elem(picture) {
    @include media-breakpoint-up(tablet) {
      margin: {
        bottom: px-to-rem(52);
      }
    }
  }
  @include elem(caption) {
    @include caption();
    margin-top: px-to-rem(24);
  }
  @include elem(container) {
    @include auto-center;
    max-width: $marketing-article-max-width;
  }
  @include elem(title) {
    @include heading-entry(h2);
  }
  @include elem(body) {
    @include auto-center();
    max-width: $content-max-width;
    padding: 0;
    position: relative;
    @include media-breakpoint-up(tablet) {
      padding-right: px-to-rem(48);
    }
  }
  @include elem(content) {
    > p {
      font: {
        family: $font-family-main;
        size: px-to-rem(18);
      }
      line-height: 1.5;
    }
  }
  @include elem(social-share) {
    align-items: center;
    display: flex;
    margin: {
      bottom: px-to-rem(24);
      top: px-to-rem(48);
    }
    @include elem(text) {
      margin: {
        bottom: 0 !important;
        right: px-to-rem(10);
      }
    }
  }
  @include elem(footer) {
    @include auto-center();
    display: flex;
    justify-content: center;
    max-width: $content-max-width;
    padding: 0;
    position: relative;
    margin-top: px-to-rem(72);
    @include media-breakpoint-up(tablet) {
      width: $article-max-width;
    }

  }
  @include elem(map) {
    border-left: 1px solid $border-color;
    padding-left: px-to-rem(54);
  }
  @include elem(extras) {
    margin: {
      left: -(px-to-rem(8));
      right: -(px-to-rem(8));
      top: px-to-rem(64);
    }
  }
  @include elem(cta) {
    height: 100%;
    margin: px-to-rem(8);
    overflow: hidden;
    @include elem(container) {
      @include media-breakpoint-down(tablet) {
        align-items: flex-start;
        display: flex;
      }
    }
    @include elem(picture) {
      height: 100%;
      margin-bottom: px-to-rem(14);
      max-height: px-to-rem(210);
      overflow: hidden;
      width: 100%;
      @include media-breakpoint-down(tablet) {
        max-height: px-to-rem(112);
        max-width: px-to-rem(190);
        width: 40%;
      }
      > img {
        min-width: 100%;
        min-height: 100%;
      }
    }
    @include elem(content) {
      @include media-breakpoint-down(tablet) {
        align-items: flex-start;
        display: flex;
        flex-direction: column-reverse;
        margin-left: px-to-rem(16);
        width: 55%;
      }
    }
    @include elem(title) {
      color: $main-text-color;
      font: {
        size: px-to-rem(18);
        weight: $font-weight-normal;
      }
      line-height: 1.4;
    }
    @include elem(description) {
      @include eyebrow();
      color: $alt-text-color !important;
      margin-top: px-to-rem(8);
    }
  }
}



