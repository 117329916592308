@include block(showcase) {
  @include clearfix-only-after();
  margin: 0 auto px-to-rem(32);
  @include media-breakpoint-down(tablet) {
    text-align: center;
  }
  @include elem(header) {
    margin-bottom: px-to-rem(16);
    position: relative;
    text-align: center;
    z-index: 5;
    @include media-breakpoint-up(desktop) {
      float: right;
      margin-top: 10%;
      padding-left: 5%;
      text-align: left;
      width: 43%;
    }
  }
  @include elem(title) {
    @include heading-display(h2);
    color: $red;
  }
  @include elem(subtitle) {
    @include heading-display(h4);
    color: $light-text-color;
    @include media-breakpoint-down(desktop) {
      display: none;
    }
  }
  @include elem(info) {
    position: relative;
    z-index: 5;
    @include media-breakpoint-up(desktop) {
      float: right;
      margin-right: 3%;
      width: 35%;
    }
  }
  @include elem(blurb) {
    @include paragraph();
    font-weight: $font-weight-light;
  }
  @include elem(button) {
    margin-top: px-to-rem(52);
  }
  @include elem(product) {
    backface-visibility: hidden;
    box-shadow: px-to-rem(24) px-to-rem(12) px-to-rem(60) rgba($black, .4);
    display: inline-block;
    font-size: 0;
    transition: transform .2s;
    width: 60%;
    @include media-breakpoint-up(tablet) {
      width: px-to-rem(300);
    }
    @include media-breakpoint-up(desktop) {
      width: px-to-rem(320);
    }
    @include media-breakpoint-up(wide) {
      width: px-to-rem(360);
    }

    @include mod(primary) {
      position: relative;
      top: 0;
      transform: translate(#{px-to-rem(32)}) rotate(1deg);
      z-index: 1;
      left: 50%;
      margin-left: -#{px-to-rem(120)};
      @include media-breakpoint-up(tablet) {
        margin-left: -#{px-to-rem(320)};
      }
      @include media-breakpoint-up(desktop) {
        margin-left: -#{px-to-rem(200)};
      }
    }
    @include mod(secondary) {
      transform: translate(-#{px-to-rem(110)}, -#{px-to-rem(10)}) rotate(-19deg) scale(.8);
    }
  }
  @include elem(products) {
    display: block;
    margin: px-to-rem(24) auto;
    outline: 0;
    position: relative;
    text-align: center;
    width: 80%;
    @include media-breakpoint-up(tablet) {
      margin: {
        bottom: px-to-rem(50);
        top: px-to-rem(50);
      }
    }
    @include media-breakpoint-up(desktop) {
      float: left;
      width: 55%;
      margin: {
        bottom: px-to-rem(50);
        top: px-to-rem(50);
      }
    }
  }
  &:hover {
    @include block(showcase) {
      @include elem(product) {
        @include mod(primary) {
          @include media-breakpoint-up(wide) {
            transform: translate(#{px-to-rem(40)}) rotate(-1deg);
          }
        }
        @include mod(secondary) {
          @include media-breakpoint-up(wide) {
            transform: translate(-#{px-to-rem(180)},-#{px-to-rem(10)}) rotate(-19deg) scale(.8);
          }
        }
      }
    }
  }

}
