@include block(icon) {
  @include antialias-font();
  background-size: cover;
  display: inline-block;
  fill: currentColor;
  height: 1em;
  transition: transform .3s ease-out;
  overflow: hidden;
  vertical-align: middle;
  width: px-to-em(16);
  @include state(fit) {
    height: 100%;
    width: 100%;
  }
  @include mod(inline) {
    position: relative;
    top: px-to-rem(10);
  }
  @include mod('grey') {
    @include link-hover();
    color: $icon-grey;
  }
  @include mod('darkgrey') {
    @include link-hover();
    color: $icon-darkgrey;
  }
  @include mod(one-and-a-half) {
    height: px-to-rem(24);
    vertical-align: bottom;
    width: px-to-rem(24);
  }
  @include mod(rotate-45) {
    transform: rotate(45deg);
  }
  @include mod(crossed) {
    &:after {
      background: currentColor;
      bottom: 0;
      content: '';
      left: px-to-rem(12);
      position: absolute;
      top: -#{px-to-rem(2)};
      transform: rotate(45deg);
      width: px-to-rem(2);
    }
  }
}

@include block(scroll-indicator-mouse) {
  @include media-breakpoint-up(tablet) {
    animation: 1s 2s ease-out forwards fadeIn, 1s 2s ease-out forwards raiseIn;
    opacity: 0;
  }
  svg {
    height: px-to-rem(30);
    width: px-to-rem(30);
  }
}
@include block(scroll-indicator-arrows) {
  display: flex;
  flex-direction: column;
  margin-top: px-to-rem(8);
  @include media-breakpoint-up(tablet) {
    animation: .5s 2.4s ease-out forwards fadeIn, .5s 2.4s ease-out forwards raiseIn;
    opacity: 0;
  }
  svg {
    height: px-to-rem(10);
    width: px-to-rem(30);
    @include media-breakpoint-up(tablet) {
      animation: pulse .5s ease-in-out infinite alternate;
    }
    &:last-child {
      animation-delay: .5s;
    }
  }
}
